import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Row,
  Input,
  Button,
  Select,
  Layout,
  Col,
  message,
  Upload,
  Modal,
  Divider,
  Tabs,
  Checkbox,
  Spin,
  InputNumber,
  Form,
  Affix,
} from "antd";
import FormData from "form-data";
import {
  DescriptionEditor,
  CompositionEditor,
  BoxEditor,
  PeriodEditor,
} from "../Editor";
import languages from "../Languages/languages.json";
import slugify from "slugify";
import api from "./../../Api";
import { ValidateToken } from "../Utils/Validator";

const { Option, OptGroup } = Select;
const { Content } = Layout;
const API_URL = process.env.REACT_APP_API_URL;
const { TabPane } = Tabs;

//show image
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

//get categories and manufacturers
const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.categories("All").get();
      setSubCategories(
        data.filter((x) => x.parentId !== 0).sort((a, b) => a.code - b.code)
      );
      setCategories(data);
    };
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data);
    };
    getCategories();
    getManufacturers();
  }, []);
  return {
    categories,
    manufacturers,
    subCategories,
  };
};

//form updates and submit, get product stored data
const useUpdateForm = (defaultValues, isNew, categories) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let copyProductID = params.get("copyProductID");

  const [inputs, setInputs] = useState(
    isNew && !copyProductID
      ? {
          code: "",
          name: [],
          description: [],
          compositionDescription: [],
          boxDescription: [],
          periodDescription: [],
          isActive: false,
          favorite: false,
          inStock: false,
          byOrder: false,
          youtube: "",
          barcode: "",
          boxBarcode: "",
          quantityInBox: 0,
          boxWeight: 0,
          weight: 0,
          volume: 0,
          slug: "",
        }
      : { ...defaultValues }
  );
  const [showDefaultImgButton, setShowDefImgButton] = useState(true);
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const [modal, setModal] = useState({
    ModalText: "Confirm product delete",
    visible: false,
    confirmLoading: false,
  });

  const [description, setDescription] = useState(inputs.description);
  const [compositionDescription, setCompositionDescription] = useState(
    inputs.compositionDescription
  );
  const [boxDescription, setBoxDescription] = useState(inputs.boxDescription);
  const [periodDescription, setPeriodDescription] = useState(
    inputs.periodDescription
  );
  const [relationProducts, setRelationProducts] = useState([]);
  const [spinning, setSpinning] = useState(true);

  //get images
  useEffect(() => {
    const ImagesUrls = async () => {
      //get product images
      const productImagesUrls = [];
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let productID = params.get("productID");
      await api
        .images()
        .get()
        .then((res) =>
          res.data.forEach((img) => {
            if (img.productId === parseInt(productID)) {
              productImagesUrls.push({
                uid: img.id,
                name: "",
                status: `${img.isDefault ? "error" : "done"}`,
                url: `${API_URL}/${img.url}`,
                isDefault: img.isDefault,
              });
            }
          })
        );
      setImages((images) => ({ ...images, fileList: productImagesUrls }));
      setOldImages(productImagesUrls);
    };

    const getProducts = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let productID = params.get("productID");
      const children = [];
      await api
        .products()
        .get()
        .then((res) => {
          res.data
            .filter((x) => x.id !== parseInt(productID))
            .forEach((item) => {
              children.push(
                <Option key={item.id} value={item.id}>
                  {item.name ? item.name[0].text : ""}
                </Option>
              );
            });
        });
      setRelationProducts(children);
    };
    getProducts();
    ImagesUrls();
    setSpinning(false);
  }, []);

  const handleSubmit = async () => {
    ValidateToken(history);

    if (!isNew && images.fileList.length === 0) {
      await message.error(`there must be at least one image`, 2);
      return;
    }
    if (
      !inputs.name.some((x) => x.languageCode === languages[0].code) ||
      (inputs.name.some((x) => x.languageCode === languages[0].code) &&
        inputs.name.find((x) => x.languageCode === languages[0].code).text ===
          "")
    ) {
      await message.error(`Product name in english is required`, 2);
      return;
    }

    if (
      inputs.categoryId &&
      categories.some((x) => x.parentId === inputs.categoryId)
    ) {
      await message.error(`Category must be at the last level`, 2);
      return;
    }

    let slug = inputs.name.some((x) => x.languageCode === languages[0].code)
      ? inputs.name.find((x) => x.languageCode === languages[0].code).text
      : inputs.name[0].text;

    let formData = new FormData();
    if (!!images.fileList) {
      for (let i = 0; i < images.fileList.length; i++) {
        formData.append("image", images.fileList[i].originFileObj);
      }
      await api
        .uploads()
        .post(formData)
        .then((res) => {
          res.data.forEach((res) => {
            api.images().post({
              productId: inputs.id,
              url: res.path,
            });
          });
        });
    }
    // get images differences
    let intersection = oldImages.filter((x) => !images.fileList.includes(x));
    for (let i = 0; i < intersection.length; i++) {
      let uid = intersection[i].uid;
      await api.images(uid).delete();
    }
    //post product data
    let data = {
      code: inputs.code,
      name: inputs.name,
      description: description,
      compositionDescription: compositionDescription,
      boxDescription: boxDescription,
      periodDescription: periodDescription,
      categoryId: inputs.categoryId,
      manufacturerId: inputs.manufacturerId,
      isActive: inputs.isActive,
      favorite: inputs.favorite,
      inStock: inputs.inStock,
      byOrder: inputs.byOrder,
      youtube: inputs.youtube,
      barcode: inputs.barcode,
      boxBarcode: inputs.boxBarcode,
      quantityInBox: inputs.quantityInBox,
      boxWeight: inputs.boxWeight,
      weight: inputs.weight,
      volume: inputs.volume,
      taxRate: inputs.taxRate,
      relations: inputs.relations ? inputs.relations : [],
      slug: slugify(slug, {
        lower: true,
      }),
    };

    if (!isNew) {
      data = { ...data, id: inputs.id };
    }

    await api
      .products()
      .post(data)
      .then(async (res) => {
        if (res.data.error) {
          await message.error(res.data.message, 2);
        } else {
          if (isNew) {
            await message.success("This product successfully saved", 1);
            history.push("/products");
          } else {
            await message.success("This product successfully edited", 1);
          }
          window.location.reload();
        }
      })
      .catch(async (err) => {
        console.log(err);
      });
  };
  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api.products(inputs.id).delete();
    images.fileList.forEach((image) => {
      api.images(image.uid).delete();
    });
    await message.warning("This product successfully deleted", 1);
    history.push(`/products`);
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleProductNameChange = (event, languageCode) => {
    event.persist();
    let data = event.target.value;
    let tmpArray = inputs.name ? inputs.name : [];
    tmpArray = tmpArray.filter((x) => x.languageCode !== languageCode);
    tmpArray.push({ languageCode: languageCode, text: data });
    setInputs((inputs) => ({
      ...inputs,
      name: tmpArray,
    }));
  };

  const handleInBoxQuantityChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      quantityInBox: value,
    }));
  };

  const handleBoxWeightChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      boxWeight: value,
    }));
  };

  const handleWeightChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      weight: value,
    }));
  };

  const handleVolumeChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      volume: value,
    }));
  };

  const handleCategoryChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      categoryId: value,
    }));
  };

  const handleManufacturerChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      manufacturerId: value,
    }));
  };

  const handleCheckBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      isActive: event.target.checked,
    }));
  };
  const handleFavoriteBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      favorite: event.target.checked,
    }));
  };
  const handleInStockBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      inStock: event.target.checked,
    }));
  };
  const handleByOrderBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      byOrder: event.target.checked,
    }));
  };

  const handleRelationsChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      relations: value,
    }));
  };

  const handleTaxRateChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      taxRate: value,
    }));
  };

  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewDefault: file.isDefault,
    }));
    if (typeof file.uid !== "number") {
      setShowDefImgButton(false);
    } else {
      setShowDefImgButton(true);
    }
  };

  const handleImageChange = ({ fileList }) => {
    setImages((images) => ({ ...images, fileList }));
  };

  return {
    handleSubmit,
    handleModalOk,
    handleModalCancel,
    showModal,
    modal,
    handleInputChange,
    handleProductNameChange,
    handleInBoxQuantityChange,
    handleBoxWeightChange,
    handleWeightChange,
    handleVolumeChange,
    handleRelationsChange,
    handleTaxRateChange,
    relationProducts,
    inputs,
    handleCategoryChange,
    handleManufacturerChange,
    handleCheckBoxChange,
    handleFavoriteBoxChange,
    handleInStockBoxChange,
    handleByOrderBoxChange,
    handleImageChange,
    handleCancel,
    handlePreview,
    images,
    setImages,
    showDefaultImgButton,
    description,
    setDescription,
    compositionDescription,
    setCompositionDescription,
    boxDescription,
    setBoxDescription,
    periodDescription,
    setPeriodDescription,
    spinning,
  };
};
//Product form component
const ProductForm = ({
  product,
  categories,
  manufacturers,
  isNew,
  subCategories,
}) => {
  const {
    inputs,
    handleInputChange,
    handleProductNameChange,
    handleInBoxQuantityChange,
    handleBoxWeightChange,
    handleWeightChange,
    handleVolumeChange,
    handleCategoryChange,
    handleManufacturerChange,
    handleCheckBoxChange,
    handleFavoriteBoxChange,
    handleInStockBoxChange,
    handleByOrderBoxChange,
    handleRelationsChange,
    handleTaxRateChange,
    relationProducts,
    modal,
    handleImageChange,
    handleSubmit,
    handleModalOk,
    handleModalCancel,
    showModal,
    handleCancel,
    handlePreview,
    images,
    setImages,
    description,
    setDescription,
    compositionDescription,
    setCompositionDescription,
    boxDescription,
    setBoxDescription,
    periodDescription,
    setPeriodDescription,
    showDefaultImgButton,
    spinning,
  } = useUpdateForm(product, isNew, categories);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const onSetGeneral = async () => {
    const defImg = images.fileList.filter(
      (el) => el.url === images.previewImage
    );
    const oldDefImg = images.fileList.filter((el) => el.isDefault === true);
    if (oldDefImg.length > 0) {
      await api.images().post({
        id: oldDefImg[0].uid,
        isDefault: false,
      });
    }
    await api.images().post({
      id: defImg[0].uid,
      isDefault: true,
    });
    setImages({ ...images, previewVisible: false });
    window.location.reload();
  };

  return (
    <Spin spinning={spinning} delay={300}>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          code: inputs.code,
        }}
      >
        <Row type="flex" justify="space-between" align="top" gutter={16}>
          <Col span={10}>
            <Form.Item name="name" label="Product name">
              <Tabs defaultActiveKey="1">
                {languages.map((language) => {
                  return (
                    <TabPane tab={language.name} key={language.id}>
                      <Input
                        type="text"
                        name="name"
                        onChange={(event) =>
                          handleProductNameChange(event, language.code)
                        }
                        value={
                          inputs.name &&
                          inputs.name.some(
                            (x) => x.languageCode === language.code
                          )
                            ? inputs.name.find(
                                (x) => x.languageCode === language.code
                              ).text
                            : ""
                        }
                      />
                    </TabPane>
                  );
                })}
              </Tabs>
            </Form.Item>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input
                type="text"
                name="code"
                onChange={handleInputChange}
                value={inputs.code}
              />
            </Form.Item>
            <Form.Item label="Category">
              <Select
                name="categoryId"
                onChange={handleCategoryChange}
                placeholder="Please select parent category"
                value={inputs.categoryId}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={0}>None</Option>
                {categories
                  .filter((x) => x.parentId === 0)
                  .map((cat) =>
                    cat.parentId === 0 &&
                    subCategories.some((x) => x.parentId === cat.id) ? (
                      <OptGroup
                        key={cat.id}
                        label={
                          cat.name &&
                          cat.name.some(
                            (x) => x.languageCode === languages[0].code
                          )
                            ? cat.name.find(
                                (x) => x.languageCode === languages[0].code
                              ).text
                            : ""
                        }
                      >
                        <Option value={cat.id} key={cat.id}>
                          {cat.name &&
                          cat.name.some(
                            (x) => x.languageCode === languages[0].code
                          )
                            ? cat.name.find(
                                (x) => x.languageCode === languages[0].code
                              ).text
                            : ""}
                        </Option>
                        {subCategories
                          .filter((x) => x.parentId === cat.id)
                          .map((subCat) => (
                            <>
                              <Option value={subCat.id} key={subCat.id}>
                                <span>-- </span>
                                {subCat.name &&
                                subCat.name.some(
                                  (x) => x.languageCode === languages[0].code
                                )
                                  ? subCat.name.find(
                                      (x) =>
                                        x.languageCode === languages[0].code
                                    ).text
                                  : ""}
                              </Option>
                              {subCategories
                                .filter((x) => x.parentId === subCat.id)
                                .map((child) => (
                                  <Option value={child.id} key={child.id}>
                                    <span>---- </span>
                                    {child.name &&
                                    child.name.some(
                                      (x) =>
                                        x.languageCode === languages[0].code
                                    )
                                      ? child.name.find(
                                          (x) =>
                                            x.languageCode === languages[0].code
                                        ).text
                                      : ""}
                                  </Option>
                                ))}
                            </>
                          ))}
                      </OptGroup>
                    ) : (
                      <Option value={cat.id} key={cat.id}>
                        {cat.name &&
                        cat.name.some(
                          (x) => x.languageCode === languages[0].code
                        )
                          ? cat.name.find(
                              (x) => x.languageCode === languages[0].code
                            ).text
                          : ""}
                      </Option>
                    )
                  )}
              </Select>
            </Form.Item>
            <Form.Item label="Manufacturer">
              <Select
                showSearch
                name="manufacturerId"
                style={{ width: "100%" }}
                placeholder="Please select manufacturer"
                onChange={handleManufacturerChange}
                value={inputs.manufacturerId}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={0}>None</Option>
                {manufacturers.map((manuf) => (
                  <Option value={manuf.id} key={manuf.id}>
                    {manuf.name &&
                    manuf.name.some((x) => x.languageCode === languages[0].code)
                      ? manuf.name.find(
                          (x) => x.languageCode === languages[0].code
                        ).text
                      : ""}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Slug">
              <Input
                name="slug"
                type="text"
                onChange={handleInputChange}
                value={inputs.slug}
                disabled
              />
            </Form.Item>
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={inputs.isActive}
                  onChange={handleCheckBoxChange}
                >
                  Is Active
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={inputs.favorite}
                  onChange={handleFavoriteBoxChange}
                >
                  Favorite
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={inputs.inStock}
                  onChange={handleInStockBoxChange}
                >
                  In stock
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={inputs.byOrder}
                  onChange={handleByOrderBoxChange}
                >
                  By order
                </Checkbox>
              </Form.Item>
            </Col>
            <Divider />
            <Form.Item>
              {!isNew && (
                <div className="clearfix">
                  <Upload
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    accept=".jpg, .jpeg, .png, .gif"
                    listType="picture-card"
                    fileList={images.fileList}
                    onPreview={handlePreview}
                    onChange={handleImageChange}
                    style={{ borderColor: "orange" }}
                    disabled={isNew}
                  >
                    {!!images.fileList && images.fileList.length >= 8
                      ? null
                      : uploadButton}
                  </Upload>
                  <Modal
                    visible={images.previewVisible}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={images.previewImage}
                    />
                    {showDefaultImgButton ? (
                      <Button
                        disabled={images.previewDefault ? true : false}
                        onClick={onSetGeneral}
                      >
                        Set General
                      </Button>
                    ) : (
                      ""
                    )}
                  </Modal>
                </div>
              )}
            </Form.Item>
            <Form.Item label="YouTube link">
              <Input
                type="text"
                name="youtube"
                onChange={handleInputChange}
                value={inputs.youtube}
                placeholder="paste here YouTube link"
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item label="Barcode">
              <Input
                type="text"
                name="barcode"
                onChange={handleInputChange}
                value={inputs.barcode}
              />
            </Form.Item>
            <Form.Item label="Box barcode">
              <Input
                type="text"
                name="boxBarcode"
                onChange={handleInputChange}
                value={inputs.boxBarcode}
              />
            </Form.Item>
            <Row type="flex" justify="start" align="middle" gutter={32}>
              <Col>
                <Form.Item label="Quantity in the box">
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    max={999}
                    onChange={handleInBoxQuantityChange}
                    value={inputs.quantityInBox}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Box weight">
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    onChange={handleBoxWeightChange}
                    value={inputs.boxWeight}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Weight (gramm)">
              <InputNumber
                min={0}
                defaultValue={0}
                max={999999}
                onChange={handleWeightChange}
                value={inputs.weight}
              />
            </Form.Item>
            <Form.Item label="Volume (liter)">
              <InputNumber
                min={0}
                defaultValue={0}
                max={999999}
                onChange={handleVolumeChange}
                value={inputs.volume}
              />
            </Form.Item>
            <Form.Item label="Tax rate">
              <Select
                style={{ width: "100%" }}
                placeholder="Please select tax rate"
                onChange={handleTaxRateChange}
                value={inputs.taxRate ? Number(inputs.taxRate) : 0.0}
              >
                <Option value={20.0}>Standard rate (20.00%)</Option>
                <Option value={10.0}>Intermediate rate (10.00%)</Option>
                <Option value={5.5}>Reduced rate (5.50%)</Option>
                <Option value={0.0}>No tax (0.00%)</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Relations">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select related products"
                onChange={handleRelationsChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={inputs.relations ? inputs.relations : []}
              >
                {relationProducts}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={32} type="flex" justify="center" align="middle">
          <Col span={12}>
            <Form.Item label="Description" style={{ width: "100%" }}>
              <Tabs defaultActiveKey="1">
                {languages.map((language) => {
                  return (
                    <TabPane tab={language.name} key={language.id}>
                      <DescriptionEditor
                        description={description}
                        setDescription={setDescription}
                        languageCode={language.code}
                      />
                    </TabPane>
                  );
                })}
              </Tabs>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Composition" style={{ width: "100%" }}>
              <Tabs defaultActiveKey="1">
                {languages.map((language) => {
                  return (
                    <TabPane tab={language.name} key={language.code}>
                      <CompositionEditor
                        description={compositionDescription}
                        setCompositionDescription={setCompositionDescription}
                        languageCode={language.code}
                      />
                    </TabPane>
                  );
                })}
              </Tabs>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32} type="flex" justify="center" align="middle">
          <Col span={12}>
            <Form.Item label="Box description" style={{ width: "100%" }}>
              <Tabs defaultActiveKey="1">
                {languages.map((language) => {
                  return (
                    <TabPane tab={language.name} key={language.id}>
                      <BoxEditor
                        description={boxDescription}
                        setBoxDescription={setBoxDescription}
                        languageCode={language.code}
                      />
                    </TabPane>
                  );
                })}
              </Tabs>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Period descriptions" style={{ width: "100%" }}>
              <Tabs defaultActiveKey="1">
                {languages.map((language) => {
                  return (
                    <TabPane tab={language.name} key={language.id}>
                      <PeriodEditor
                        description={periodDescription}
                        setPeriodDescription={setPeriodDescription}
                        languageCode={language.code}
                      />
                    </TabPane>
                  );
                })}
              </Tabs>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Affix offsetBottom={0}>
          <Row
            type="flex"
            gutter={32}
            justify="center"
            style={{
              background: "#f0f2f5",
              padding: "16px",
              borderRadius: 12,
              border: "1px solid rgba(0, 0, 0, 0.06)",
            }}
          >
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {isNew ? "Save" : "Update"}
                </Button>
              </Form.Item>
            </Col>
            {!isNew && (
              <Col>
                <Form.Item>
                  <Button type="danger" onClick={showModal}>
                    Delete
                  </Button>
                  <Modal
                    title="Are you sure?"
                    visible={modal.visible}
                    onOk={handleModalOk}
                    confirmLoading={modal.confirmLoading}
                    onCancel={handleModalCancel}
                  >
                    <p>{modal.ModalText}</p>
                  </Modal>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Affix>
      </Form>
    </Spin>
  );
};

//Product component
const Product = () => {
  const { categories, manufacturers, subCategories } = useCategories();
  const [product, setProduct] = useState({});
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isNew = params.get("isNew") === "true";
  let copyProductID = params.get("copyProductID");
  let productID = params.get("productID");

  useEffect(() => {
    const fetchProduct = async () => {
      await api
        .products(productID > 0 ? productID : copyProductID)
        .get()
        .then((res) => {
          if (productID) {
            setProduct(res.data[0]);
          } else {
            setProduct({ ...res.data[0], code: "", name: "", slug: "" });
          }
        })
        .catch((error) => console.log(error));
    };
    fetchProduct();
  }, [productID, copyProductID]);

  return (
    <div className="product">
      <Link className="back-icon" to="/products">
        <ArrowLeftOutlined />
      </Link>
      <Layout>
        <Content style={{ padding: "32px 50px" }}>
          <h1>{isNew ? "Add New" : ""}</h1>
          <Divider />
          {isNew && !copyProductID ? (
            <ProductForm
              product={product}
              categories={categories}
              manufacturers={manufacturers}
              isNew={isNew}
              subCategories={subCategories}
            />
          ) : (
            !!product.id && (
              <ProductForm
                product={product}
                categories={categories}
                manufacturers={manufacturers}
                isNew={isNew}
                subCategories={subCategories}
              />
            )
          )}
        </Content>
      </Layout>
    </div>
  );
};
export default Product;
