import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Cookie from "js-cookie";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import FileRepository from "@ckeditor/ckeditor5-upload/src/filerepository";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";

const PageEditor = ({ description, setDescription, languageCode }) => {
  const onDescriptionChange = (event, editor) => {
    const data = editor.getData();
    let tmpDesc = description ? description : [];
    tmpDesc = tmpDesc.filter((x) => x.languageCode !== languageCode);
    tmpDesc.push({ languageCode: languageCode, text: data });

    setDescription(tmpDesc);
  };
  
  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
    plugins: [
      Essentials,
      Autoformat,
      Paragraph,
      Bold,
      Italic,
      Heading,
      Link,
      List,
      Alignment,
      Image,
      ImageToolbar,
      ImageCaption,
      ImageStyle,
      ImageResize,
      FileRepository,
      ImageUpload,
      BlockQuote,
    ],
    toolbar: [
      "heading",
      "|",
      "alignment",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "blockQuote",
      "imageUpload",
      "|",
      "undo",
      "redo",
    ],
    image: {
      // Configure the available styles.
      styles: ["alignLeft", "alignCenter", "alignRight"],

      // You need to configure the image toolbar, too, so it shows the new style
      // buttons as well as the resize buttons.
      toolbar: [
        "imageStyle:alignLeft",
        "imageStyle:alignCenter",
        "imageStyle:alignRight",
        "|",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        onInit={(editor) => console.log("Editor is ready to use!", editor)}
        onChange={onDescriptionChange}
        config={editorConfiguration}
        data={
          description &&
          description.some((x) => x.languageCode === languageCode)
            ? description.find((x) => x.languageCode === languageCode).text
            : ""
        }
      />
    </div>
  );
};

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
    this.url = process.env.REACT_APP_API_URL + "/uploads/";
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open("POST", this.url, true);
    xhr.responseType = "json";
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null}`
    );
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const API_URL = process.env.REACT_APP_API_URL;
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldnot upload file:' + ${loader.file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }
      console.log(response);
      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: API_URL + "/" + response[0].path,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();
    this.loader.file.then((result) => {
      data.append("image", result);
      this.xhr.send(data);
    });
  }
}

export default PageEditor;
