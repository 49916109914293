import React, { useState, useEffect } from "react";
import {
  Row,
  Divider,
  message,
  List,
  Button,
  InputNumber,
  Select,
} from "antd";
import api from "../../Api";
import { ProductName, StorageName } from "../Utils/Common";
import { PDFExport } from "@progress/kendo-react-pdf";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const { Option } = Select;

const OrderDetails = ({ order, customers }) => {
  const [products, setProducts] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [pdfExportComponent, setPdfExportComponent] = useState();
  const [deletedRows, setDeletedRows] = useState([]);
  const [changedRows, setChangedRows] = useState([]);
  const [totalSum, setTotalSum] = useState(order.totalSum);
  const [showEditButtons, setShowEditButtons] = useState(false);
  const [prices, setPrices] = useState([]);
  const [storages, setStorages] = useState([]);

  const exportPDF = () => {
    pdfExportComponent.save();
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportXLS = () => {
    let excelData = [];
    excelData.push(...orderDetails);

    excelData.forEach((row, index) => {
      row.quantity = Number(row.quantity);
      row.price = Number(row.price);
      row.totalPrice = Number((row.quantity * row.price).toFixed(2));
      row.orderDate =
        new Date(order.date).getFullYear() +
        "-" +
        (new Date(order.date).getMonth() + 1) +
        "-" +
        new Date(order.date).getDate();
      row.customer =
        customers && customers.some((x) => x.id === order.customerUid)
          ? customers.find((x) => x.id === order.customerUid).businessName
          : "";
      row.address =
        customers && customers.some((x) => x.id === order.customerUid)
          ? customers.find((x) => x.id === order.customerUid).address1 +
            " - " +
            customers.find((x) => x.id === order.customerUid).phone1
          : "";
      row.id = index + 1;
      row.productId = ProductName(products.find((x) => x.id === row.productId));
      row.notes = order.notes ? order.notes : "";
    });
    excelData.push({ totalPrice: Number(totalSum) });

    const ws = XLSX.utils.json_to_sheet(excelData, { origin: { r: 0, c: 0 } });
    var wscols = [
      { wch: 6 },
      { wch: 20 },
      { wch: 30 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 30 },
      { wch: 50 },
      { wch: 30 },
    ];

    ws["!cols"] = wscols;

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `Report for order ${
        excelData[0].orderUid +
        "-" +
        new Date(order.date).getFullYear() +
        "-" +
        (new Date(order.date).getMonth() + 1) +
        "-" +
        new Date(order.date).getDate()
      }` + fileExtension
    );
  };

  const setTotals = () => {
    let total = orderDetails.reduce((a, b) => a + b.price * b.quantity, 0);
    setTotalSum(Number(total.toFixed(2)));
  };

  const handleOrderRowDelete = (rowId) => {
    let tempRows = [...deletedRows];
    tempRows.push(rowId);
    setDeletedRows(tempRows);
    setOrderDetails(orderDetails.filter((x) => x.id !== rowId));
    let total = orderDetails
      .filter((x) => x.id !== rowId)
      .reduce((a, b) => a + b.price * b.quantity, 0);
    setTotalSum(Number(total.toFixed(2)));
  };

  const handlePriceChange = (price, rowId) => {
    let tempRows = [...changedRows];
    if (tempRows && tempRows.some((x) => x.rowId === rowId)) {
      let index = tempRows.findIndex((x) => x.rowId === rowId);
      tempRows[index].price = price;
    } else {
      tempRows.push({ id: rowId, price: price });
    }
    orderDetails.find((x) => x.id === rowId).price = price;
    setChangedRows(tempRows);
    setTotals();
  };

  const handleQuantityChange = (quantity, rowId) => {
    let tempRows = [...changedRows];
    if (tempRows && tempRows.some((x) => x.id === rowId)) {
      let index = tempRows.findIndex((x) => x.id === rowId);
      tempRows[index].quantity = quantity;
    } else {
      tempRows.push({ id: rowId, quantity: quantity });
    }
    orderDetails.find((x) => x.id === rowId).quantity = quantity;
    setChangedRows(tempRows);
    setTotals();
  };

  const editOrder = () => {
    setShowEditButtons(true);
  };

  const updateOrder = async () => {
    let err = false;
    orderDetails.forEach((row, index) => {
      if (
        orderDetails.some(
          (x) =>
            x.productId === row.productId &&
            row.orderUid === "" &&
            x.orderUid !== ""
        )
      ) {
        message.error(
          `There is a product with the same name ${index + 1} , ${
            orderDetails.findIndex(
              (x) =>
                x.productId === row.productId &&
                row.orderUid === "" &&
                x.orderUid !== ""
            ) + 1
          }`,
          1
        );
        err = true;
      }
    });
    if (orderDetails.length === 0) {
      message.error(`Products list is empty`, 1);
      err = true;
    }
    if (orderDetails.some((x) => x.storageId === 0)) {
      message.error(`Warehouse is required`, 1);
      err = true;
    }
    if (err) {
      return;
    }
    const ordersBody = [];
    //for new rows
    orderDetails
      .filter((x) => x.orderUid === "")
      .forEach((row) => {
        row.orderUid = order.id;
        ordersBody.push(row);
      });
    //for changed rows
    if (changedRows) {
      changedRows
        .filter((x) => x.id !== undefined)
        .forEach((row) => {
          ordersBody.push(row);
        });
    }
    await api
      .orders()
      .post(ordersBody)
      .then((res) => console.log(res.data))
      .catch((ex) => console.log(ex.data));
    //for deleted rows
    if (deletedRows.length > 0) {
      await api
        .orders()
        .delete({
          params: { ids: deletedRows },
        })
        .then((res) => console.log(res.data))
        .catch((ex) => console.log(ex.data));
    }
    if (changedRows || deletedRows) {
      await api
        .documents()
        .post({ id: order.id, totalSum: totalSum })
        .then((res) => console.log(res.data))
        .catch((ex) => console.log(ex.data));
      message.success("Order updated", 1);
      window.location.reload();
    }
    setShowEditButtons(false);
  };

  useEffect(() => {
    const getOrderDetails = async () => {
      await api
        .orders(order.id)
        .get()
        .then((response) => setOrderDetails(response.data))
        .catch((err) => console.log(err));
    };
    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((response) => {
          setProducts(response.data);
          setLoadingProducts(false);
        })
        .catch((err) => console.log(err));
    };
    const getStorages = async () => {
      await api
        .storages()
        .get()
        .then((response) => {
          setStorages(response.data);
        })
        .catch((err) => console.log(err));
    };
    const getPrices = async () => {
      await api
        .actualPrices(order.customerUid)
        .get()
        .then((res) => {
          setPrices(res.data);
        })
        .catch((err) => {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            console.log(err);
          }
        });
    };
    getOrderDetails();
    getProducts();
    getStorages();
    getPrices();
  }, [order.id, order.customerUid]);

  const handleAddProduct = () => {
    let tmpRows = [...orderDetails];

    tmpRows.push({
      orderUid: "",
      productId: 0,
      quantity: "1",
      price: "0",
      storageId: storages.find((x) => x.isDefault).id,
    });
    setOrderDetails(tmpRows);
  };

  const handleProductSelect = (productId, index) => {
    let tmpRows = [...orderDetails];

    tmpRows[index].productId = productId;
    if (prices.some((x) => x.productId === productId)) {
      tmpRows[index].price = prices.find(
        (x) => x.productId === productId
      ).price;
    }
    setOrderDetails(tmpRows);
    setTotals();
  };

  const handleStorageSelect = (storageId, rowId) => {
    let tempRows = [...changedRows];
    if (tempRows && tempRows.some((x) => x.rowId === rowId)) {
      let index = tempRows.findIndex((x) => x.rowId === rowId);
      tempRows[index].storageId = storageId;
    }
    orderDetails.find((x) => x.id === rowId).storageId = storageId;
    setChangedRows(tempRows);
    setTotals();
  };

  return (
    <>
      <PDFExport
        ref={(component) => setPdfExportComponent(component)}
        paperSize="A4"
        margin={40}
        fileName={`Report for ${
          new Date(order.date).getFullYear() +
          "-" +
          (new Date(order.date).getMonth() + 1) +
          "-" +
          new Date(order.date).getDate()
        }`}
        author="Owltech team"
      >
        <div>
          Order N:
          {order.id}
        </div>
        <div className="order-date">
          Order date:
          {" " +
            new Date(order.date).getFullYear() +
            "-" +
            (new Date(order.date).getMonth() + 1) +
            "-" +
            new Date(order.date).getDate()}
        </div>
        <div className="order-date">
          Customer:
          {" " + customers && customers.some((x) => x.id === order.customerUid)
            ? customers.find((x) => x.id === order.customerUid).businessName
            : ""}
        </div>
        <div className="order-date">
          Notes:
          {" " + order.notes ? order.notes : ""}
        </div>
        <div>
          {customers && customers.some((x) => x.id === order.customerUid)
            ? customers.find((x) => x.id === order.customerUid).address1 +
              " - " +
              customers.find((x) => x.id === order.customerUid).phone1
            : ""}
        </div>
        <Button
          type="primary"
          className="green-btn"
          size="small"
          disabled={!showEditButtons}
          icon={<PlusOutlined />}
          onClick={handleAddProduct}
        ></Button>
        <List
          style={{ width: "100%" }}
          itemLayout="horizontal"
          loading={loadingProducts}
          size="small"
          footer={"Total " + totalSum + " €"}
          dataSource={orderDetails}
          renderItem={(row, index) => (
            <List.Item key={index}>
              <List.Item.Meta
                title={
                  row.orderUid === "" ? (
                    <>
                      <span>{(index + 1).toString() + ". "}</span>
                      <Select
                        showSearch
                        allowClear
                        style={{ width: "80%" }}
                        placeholder="Please select product"
                        onChange={(value) => handleProductSelect(value, index)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {products
                          .filter(
                            ({ id: id1 }) =>
                              !orderDetails.some(
                                ({ productId: id2, orderUid: id3 }) =>
                                  id2 === id1 && id3 !== ""
                              )
                          )
                          .map((product) => (
                            <Option value={product.id} key={product.id}>
                              {ProductName(product)}
                            </Option>
                          ))}
                      </Select>
                    </>
                  ) : (
                    (index + 1).toString() +
                    ". " +
                    ProductName(products.find((x) => x.id === row.productId))
                  )
                }
              />
              <div style={{ marginRight: 12 }}>
                {showEditButtons ? (
                  <Select
                    showSearch
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select warehouse"
                    defaultValue={storages.find((x) => x.isDefault).id}
                    onChange={(value) => handleStorageSelect(value, row.id)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {storages.map((storage) => (
                      <Option value={storage.id} key={storage.id}>
                        {StorageName(storage)}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  StorageName(storages.find((x) => x.id === row.storageId))
                )}
              </div>
              <div style={{ marginRight: 12 }}>
                Price:{" "}
                {showEditButtons ? (
                  <InputNumber
                    size="small"
                    min={0}
                    onChange={(value) => handlePriceChange(value, row.id)}
                    value={row.price}
                  />
                ) : (
                  row.price
                )}
              </div>
              <div>
                Quantity:{" "}
                {showEditButtons ? (
                  <InputNumber
                    size="small"
                    min={1}
                    onChange={(value) => handleQuantityChange(value, row.id)}
                    value={row.quantity}
                  />
                ) : (
                  row.quantity
                )}
              </div>
              <Button
                type="primary"
                size="small"
                style={{
                  marginLeft: 4,
                  display: showEditButtons ? "block" : "none",
                }}
                danger
                onClick={() => handleOrderRowDelete(row.id)}
              >
                -
              </Button>
            </List.Item>
          )}
        />
      </PDFExport>
      <Row type="flex" justify="center" align="middle">
        <Divider />
        <Button
          icon={<EditOutlined />}
          type="primary"
          onClick={showEditButtons ? updateOrder : editOrder}
          style={{ marginRight: 6 }}
        >
          {showEditButtons ? "Update order" : "Edit order"}
        </Button>
        <Divider />
        <Button
          style={{ marginRight: 6 }}
          disabled={showEditButtons}
          onClick={exportPDF}
          danger
          type="primary"
        >
          Download pdf
        </Button>
        <Button
          type="primary"
          disabled={showEditButtons}
          onClick={exportXLS}
          className="green-btn"
        >
          Download xlsx
        </Button>
      </Row>
    </>
  );
};

export default OrderDetails;
