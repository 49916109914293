import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  CodeSandboxOutlined,
  ContainerOutlined,
  CopyrightOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FolderAddOutlined,
  FormOutlined,
  HddOutlined,
  HomeOutlined,
  NotificationOutlined,
  PercentageOutlined,
  PictureOutlined,
  ReadOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import logo from "../Images/logo.svg";
import Cookie from "js-cookie";
import { isValidToken } from "./Utils/Validator";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(!isValidToken());
  let location = useLocation();

  const onCollapse = (value) => {
    if (isValidToken()) {
      setCollapsed(value);
    }
  };
  const [subMenuState, setSubMenuState] = useState({
    openKeys: [location.pathname.substring(1, 4)],
  });
  useEffect(() => {
    const selectedParent = async () => {
      let productsMenuList = ["pro", "cat", "man", "spe"];
      let customersMenuList = ["cus", "ord"];
      let pricesMenuList = ["pri, dis, cpr"];
      let urlSubstring = location.pathname.substring(1, 4);
      if (productsMenuList.includes(urlSubstring)) {
        setSubMenuState({ openKeys: ["products"] });
      }
      if (customersMenuList.includes(urlSubstring)) {
        setSubMenuState({ openKeys: ["customers"] });
      }
      if (pricesMenuList.includes(urlSubstring)) {
        setSubMenuState({ openKeys: ["prices"] });
      }
      if (urlSubstring === "") {
        setSubMenuState({ openKeys: ["gen"] });
      }
    };
    selectedParent();
  }, [location.pathname]);

  const onOpenChange = (openKeys) => {
    let rootSubmenuKeys = ["products", "customers", "prices"];
    const latestOpenKey = openKeys.find(
      (key) => subMenuState.openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setSubMenuState({ openKeys });
    } else {
      setSubMenuState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      style={{ minHeight: "100vh" }}
      breakpoint="md"
    >
      <Link to="/">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
      </Link>
      {!Cookie.get("jwtToken") ||
      Cookie.get("jwtToken") === "" ||
      Cookie.get("expTime") < Date.now() / 1000 ? (
        ""
      ) : (
        <Menu
          theme="dark"
          mode="inline"
          onOpenChange={onOpenChange}
          defaultSelectedKeys={
            location.pathname.substring(1, 4) === ""
              ? ["gen"]
              : [location.pathname.substring(1, 4)]
          }
          defaultOpenKeys={subMenuState.openKeys}
          openKeys={subMenuState.openKeys}
          selectedKeys={
            location.pathname.substring(1, 4) === ""
              ? ["gen"]
              : [location.pathname.substring(1, 4)]
          }
        >
          <Menu.Item key="gen">
            <Link to="/">
              <DashboardOutlined />
              <span className="nav-text">General</span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="products"
            title={
              <span>
                <DatabaseOutlined />
                <span>Products</span>
              </span>
            }
          >
            <Menu.Item key="pro">
              <Link to="/products">
                <CodeSandboxOutlined />
                <span className="nav-text">Products</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="cat">
              <Link to="/categories">
                <ContainerOutlined />
                <span className="nav-text">Categories</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="man">
              <Link to="/manufacturers">
                <CopyrightOutlined />
                <span className="nav-text">Manufacturers</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="customers"
            title={
              <span>
                <TeamOutlined />
                <span>Customers</span>
              </span>
            }
          >
            <Menu.Item key="cus">
              <Link to="/customers">
                <SolutionOutlined />
                <span className="nav-text">Customers</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="ord">
              <Link to="/orders">
                <FileDoneOutlined />
                <span className="nav-text">Orders</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="prices"
            title={
              <span>
                <DollarOutlined />
                <span>Prices and discounts</span>
              </span>
            }
          >
            <Menu.Item key="pri">
              <Link to="/prices">
                <DollarOutlined />
                <span className="nav-text">Prices</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="cpr">
              <Link to="/cprices">
                <DollarOutlined />
                <span className="nav-text">Customer prices</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="dis">
              <Link to="/discounts">
                <PercentageOutlined />
                <span className="nav-text">Discounts</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="sto">
            <Link to="/storages">
              <HomeOutlined />
              <span className="nav-text">Warehouses</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="inp">
            <Link to="/inputs">
              <FolderAddOutlined />
              <span className="nav-text">Inputs</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="rem">
            <Link to="/remainders">
              <HddOutlined />
              <span className="nav-text">Remainders</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="pag">
            <Link to="/pages">
              <FormOutlined />
              <span className="nav-text">Pages</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="pos">
            <Link to="/posts">
              <ReadOutlined />
              <span className="nav-text">Posts</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="ban">
            <Link to="/banners">
              <PictureOutlined />
              <span className="nav-text">Banners</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="prm">
            <Link to="/prmotions">
              <NotificationOutlined />
              <span className="nav-text">Promotions</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="set">
            <Link to="/settings">
              <SettingOutlined />
              <span className="nav-text">Settings</span>
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </Sider>
  );
};
export default SideBar;
