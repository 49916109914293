import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  LoadingOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  CloudUploadOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";
import {
  Form,
  Row,
  Input,
  Button,
  Layout,
  Col,
  message,
  Divider,
  Spin,
  Select,
  Upload,
  Modal,
  Checkbox,
} from "antd";
import api from "../../Api";

const { Content } = Layout;
const { Option } = Select;
const API_URL = process.env.REACT_APP_API_URL;

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

//form updates and submit, get customer stored data
const CustomerForm = ({ customer, isNew, information }) => {
  const [images, setImages] = useState({
    fileList:
      customer && customer.imageUrl
        ? [
            {
              uid: customer ? customer : -1,
              name: "image",
              status: "done",
              url: `${customer.imageUrl ? customer.imageUrl : ""}`,
            },
          ]
        : [],
  });
  const [axonautButtonLoading, setAxonautButtonLoading] = useState(false);
  const [axonautUpdateChecked, setAxonautUpdateChecked] = useState(
    localStorage.getItem("customer-axonaut-checked") === "true" ? true : false
  );
  const [modal, setModal] = useState({
    ModalText: "Confirm customer delete",
    visible: false,
    confirmLoading: false,
  });

  const externalId = customer ? customer.externalId : 0;

  const history = useHistory();

  const handleSubmit = async (values) => {
    if (!isNew) {
      let formData = new FormData();
      for (let i = 0; i < images.fileList.length; i++) {
        formData.append("image", images.fileList[i].originFileObj);
      }
      api
        .uploads()
        .post(formData)
        .then(async (response) => {
          response.data.forEach((res) => {
            api.saveCustomer().post({
              id: customer.id,
              imageUrl: `${API_URL}/${res.path}`,
            });
          });
        });
    }

    //post customer data
    let data = {
      username: values.username ? values.username : "",
      businessName: values.businessName ? values.businessName : "",
      country: values.country ? values.country : "",
      email: values.email ? values.email : "",
      TIN: values.TIN ? values.TIN : "",
      altName: values.altName ? values.altName : "",
      city: values.city ? values.city : "",
      phone1: values.phone1 ? values.phone1 : "",
      phone2: values.phone2 ? values.phone2 : "",
      legalAddress: values.legalAddress ? values.legalAddress : "",
      address1: values.address1 ? values.address1 : "",
      address2: values.address2 ? values.address2 : "",
      state: values.state && values.state === "" ? "unverified" : values.state,
      contact: values.contact ? values.contact : "",
      zipCode: values.zipCode ? values.zipCode : "",
      region: values.region ? values.region : "",
      businessManager: values.businessManager ? values.businessManager : "",
      imageUrl: images.fileList
        ? images.fileList.length === 0
          ? ""
          : images.fileList.url
        : "",
    };

    if (isNew) {
      data = {
        ...data,
        password: values.password,
      };
    } else {
      data = {
        ...data,
        id: customer.id,
      };
    }
    await api
      .saveCustomer()
      .post(data)
      .then(async (res) => {
        if (isNew) {
          await message.success("This customer successfully created", 1);
          history.push(`/customers`);
          window.location.reload();
        } else {
          localStorage.setItem(
            "customer-axonaut-checked",
            axonautUpdateChecked
          );

          if (axonautUpdateChecked) {
            await axonautButtonOnClick(values.state);
          }
          await message.success("This customer successfully edited", 1);
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error(
          err.response.data ? err.response.data.message : "something is wrong"
        );
      });
  };

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    let err = false;
    await api
      .customerDocuments(customer.id)
      .get()
      .then((res) => {
        if (res.data.length > 0) {
          message.error("There are orders for customer", 1);
          err = true;
        }
      });
    if (!err) {
      await api.deleteCustomer().delete({
        params: { id: customer.id },
      });
      await message.warning("This customer successfully deleted", 1);
      history.push(`/customers`);
    }
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  const handleImageChange = (img) => {
    setImages((images) => ({ ...images, fileList: img.fileList }));
  };

  const uploadButton = (
    <div>
      {images.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
    }));
  };

  const axonautButtonOnClick = async (state) => {
    if (customer.state === "verified" || state === "verified") {
      setAxonautButtonLoading(true);
      await api
        .addAxonautCustomers(customer.id)
        .patch()
        .then(async (res) => {
          if (res.data === "Updated") {
            message.success("Successfully uploaded to Axonaut", 1);
            setAxonautButtonLoading(false);
          } else {
            await message.error(res.data, 1);
          }
        })
        .catch((ex) => {
          setAxonautButtonLoading(false);
          message.error(ex.message, 2);
        });
    } else {
      message.error("Customer is not verified", 1);
    }
    setAxonautButtonLoading(false);
  };

  const axonautPostButtonOnClick = async () => {
    if (customer.state === "verified") {
      setAxonautButtonLoading(true);
      await api
        .addAxonautCustomers(customer.id)
        .post()
        .then(async (res) => {
          if (res.data === "Created") {
            setAxonautButtonLoading(false);
            await message.success("This customer successfully edited", 1);
            window.location.reload();
          } else {
            setAxonautButtonLoading(false);
            await message.error(res.data, 1);
          }
        })
        .catch((ex) => message.error(ex.message, 2));
    } else {
      message.error("Customer is not verified", 1);
    }
    setAxonautButtonLoading(false);
  };

  const handleAxonautUpdateChecked = () => {
    setAxonautUpdateChecked(!axonautUpdateChecked);
  };

  return (
    <Form initialValues={customer} layout="vertical" onFinish={handleSubmit}>
      <Row type="flex" justify="space-between" align="top" gutter={16}>
        <Col span={12}>
          <Form.Item label="Username (Login)" name="username">
            <Input />
          </Form.Item>
          {isNew ? (
            <div>
              <Form.Item label="Password" name="password">
                <Input type="password" />
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          <Form.Item label="Company Name" name="businessName">
            <Input />
          </Form.Item>
          <Form.Item label="Legal Address" name="legalAddress">
            <Input />
          </Form.Item>
          <Form.Item label="Address 1" name="address1">
            <Input />
          </Form.Item>
          <Form.Item label="Address 2" name="address2">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input type="email" />
          </Form.Item>
          <Form.Item label="Alternative name" name="altName">
            <Input />
          </Form.Item>
          <Form.Item label="TIN" name="TIN">
            <Input />
          </Form.Item>
          <Form.Item label="Country" name="country">
            <Input />
          </Form.Item>
          <Form.Item label="City" name="city">
            <Input />
          </Form.Item>
          <Form.Item label="Phone 1" name="phone1">
            <Input />
          </Form.Item>
          <Form.Item label="Phone 2" name="phone2">
            <Input />
          </Form.Item>
          <Form.Item label="Region" name="region">
            <Input />
          </Form.Item>
          <Form.Item label="Zip Code" name="zipCode">
            <Input />
          </Form.Item>
          <Form.Item label="Contact" name="contact">
            <Input />
          </Form.Item>
          <Form.Item label="Manager mail" name="businessManager">
            <Input type="email" />
          </Form.Item>
          <Form.Item label="ExternalId">
            <Row gutter={8}>
              <Col span={12}>
                <Input name="externalId" value={externalId} disabled />
              </Col>
              {information.axonautToken && information.axonautToken !== "" && (
                <Col span={12}>
                  <Button
                    disabled={isNew || (!isNew && externalId === 0)}
                    loading={axonautButtonLoading}
                    onClick={axonautButtonOnClick}
                    icon={<CloudSyncOutlined />}
                  >
                    Update in Axonaut
                  </Button>
                  <Button
                    disabled={isNew || externalId > 0}
                    loading={axonautButtonLoading}
                    onClick={axonautPostButtonOnClick}
                    icon={<CloudUploadOutlined />}
                  >
                    Upload to Axonaut
                  </Button>
                </Col>
              )}
            </Row>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <div
              style={{ visibility: isNew ? "hidden" : "visible" }}
              className="clearfix"
            >
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                accept=".jpg, .jpeg, .png, .gif"
                listType="picture-card"
                fileList={images.fileList}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleImageChange}
              >
                {!!images.fileList && images.fileList.length >= 1
                  ? null
                  : uploadButton}
              </Upload>
              <Modal
                visible={images.previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={images.previewImage}
                />
              </Modal>
            </div>
          </Form.Item>
          <Form.Item label="State" name="state">
            <Select disabled={isNew} defaultValue="unverified">
              <Option value="unverified">Unverified</Option>
              <Option value="verified">Verified</Option>
              <Option value="blocked">Blocked</Option>
            </Select>
          </Form.Item>
          {!isNew && !isNaN(new Date(customer.requestDate).getFullYear()) ? (
            <Form.Item label="Request/Registration date">
              {new Date(customer.requestDate).getFullYear() +
                "-" +
                (new Date(customer.requestDate).getMonth() + 1) +
                "-" +
                new Date(customer.requestDate).getDate()}
            </Form.Item>
          ) : (
            ""
          )}
          {!isNew &&
          customer.state === "verified" &&
          !isNaN(new Date(customer.verificationDate).getFullYear()) ? (
            <Form.Item label="Verification date">
              {new Date(customer.verificationDate).getFullYear() +
                "-" +
                (new Date(customer.verificationDate).getMonth() + 1) +
                "-" +
                new Date(customer.verificationDate).getDate()}
            </Form.Item>
          ) : (
            ""
          )}
          {!isNew &&
          customer.state === "blocked" &&
          !isNaN(new Date(customer.blockedDate).getFullYear()) ? (
            <Form.Item label="Blocked date">
              {customer.blockedDate
                ? new Date(customer.blockedDate).getFullYear() +
                  "-" +
                  (new Date(customer.blockedDate).getMonth() + 1) +
                  "-" +
                  new Date(customer.blockedDate).getDate()
                : ""}
            </Form.Item>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="center" align="middle">
        <Row type="flex" gutter={32} align="middle">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {isNew ? "Create" : "Update"}
              </Button>
            </Form.Item>
          </Col>
          {information.axonautToken && information.axonautToken !== "" && (
            <Col>
              <Form.Item name="axonaut">
                <Checkbox
                  checked={axonautUpdateChecked}
                  onChange={handleAxonautUpdateChecked}
                  disabled={isNew && !externalId}
                >
                  Update Also in Axonaut
                </Checkbox>
              </Form.Item>
            </Col>
          )}
          <Col>
            {isNew ? (
              ""
            ) : (
              <Form.Item>
                <Button type="danger" onClick={showModal}>
                  Delete
                </Button>
                <Modal
                  title="Are you sure?"
                  visible={modal.visible}
                  onOk={handleModalOk}
                  confirmLoading={modal.confirmLoading}
                  onCancel={handleModalCancel}
                >
                  <p>{modal.ModalText}</p>
                </Modal>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Row>
    </Form>
  );
};
//Page component
const Customer = () => {
  const [customer, setCustomer] = useState({});
  const [spinning, setSpinning] = useState(true);
  const [information, setInformation] = useState({});

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isNew = params.get("isNew") === "true";
  useEffect(() => {
    const fetchCustomer = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let customerID = params.get("customerID");

      await api
        .getCustomers()
        .get({
          params: { id: customerID },
        })
        .then((res) => {
          setCustomer(res.data);
          setSpinning(false);
        })
        .catch((err) => console.log(err));
    };
    const getInformation = async () => {
      await api
        .informations(1)
        .get()
        .then((response) => setInformation(response.data))
        .catch((ex) => console.log(ex.data));
    };
    getInformation();
    fetchCustomer();
  }, []);

  return (
    <div className="customer">
      <Link className="back-icon" to="/customers">
        <ArrowLeftOutlined />
      </Link>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>{isNew ? "Add New" : customer.businessName}</h1>
            <Divider />
            {isNew ? (
              <CustomerForm
                customer={customer}
                isNew={isNew}
                information={information}
              />
            ) : (
              !!customer.id && (
                <CustomerForm
                  customer={customer}
                  isNew={isNew}
                  information={information}
                />
              )
            )}
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Customer;
