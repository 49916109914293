import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { List, Layout, Button, Row, Col } from "antd";
import api from "../../Api";
import languages from "../Languages/languages.json";

const { Content } = Layout;

const ManufacturersDataView = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [nextId, setNextId] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setNextId(
        Math.max.apply(
          Math,
          data.map(function (o) {
            return o.id;
          })
        ) + 1
      );
      setManufacturers(data);
      setLoading(false);
    };
    getManufacturers();
  }, []);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <h1>Manufacturers</h1>
        </Col>
        <Col span={12}>
          <div className="addNew">
            <Link
              to={{
                pathname: `/manufacturer/?manufacturerID=${nextId}&isNew=true`,
              }}
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="green-btn"
              >
                {" "}
                Add new
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "left",
          borderRadius: 12,
        }}
      >
        <List
          itemLayout="horizontal"
          loading={loading}
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 30,
          }}
          dataSource={manufacturers}
          renderItem={(manufacturer) => (
            <List.Item key={manufacturer.id}>
              <h2>{manufacturer.code}</h2>
              <List.Item.Meta
                title={
                  manufacturer.name &&
                  manufacturer.name.some(
                    (x) => x.languageCode === languages[0].code
                  )
                    ? manufacturer.name.find(
                        (x) => x.languageCode === languages[0].code
                      ).text
                    : ""
                }
                description={
                  manufacturer.description &&
                  manufacturer.description.some(
                    (x) => x.languageCode === languages[0].code
                  )
                    ? manufacturer.description.find(
                        (x) => x.languageCode === languages[0].code
                      ).text
                    : ""
                }
              />
              <Link
                to={{
                  pathname: `/manufacturer/?manufacturerID=${manufacturer.id}`,
                }}
              >
                <Button>Show/Edit</Button>
              </Link>
            </List.Item>
          )}
        />
      </div>
    </Content>
  );
};
export default ManufacturersDataView;
