import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Row,
  Input,
  Button,
  Layout,
  Col,
  message,
  Tabs,
  Divider,
  Modal,
  Spin,
  Checkbox,
  Form,
  InputNumber,
} from "antd";
import languages from "../Languages/languages.json";
import api from "../../Api";

const { Content } = Layout;
const { TabPane } = Tabs;

const StorageForm = ({ storage, isNew }) => {
  const [inputs, setInputs] = useState(
    isNew
      ? {
          name: [],
          address: "",
        }
      : storage
  );

  const [modal, setModal] = useState({
    ModalText: "Confirm storage delete",
    visible: false,
    confirmLoading: false,
  });

  const handleSubmit = async () => {
    const { data } = await api.storages().get();
    if (
      inputs.isDefault &&
      data.some((x) => x.isDefault && x.id !== inputs.id)
    ) {
      await message.error("There can be only one default warehouse", 2);
      return;
    }

    let storageBody = {
      code: inputs.code,
      name: inputs.name,
      address: inputs.address,
      manager: inputs.manager,
      area: inputs.area,
      isActive: inputs.isActive,
      isDefault: inputs.isDefault,
    };
    if (!isNew) {
      storageBody = { ...storageBody, id: inputs.id };
    }
    await api
      .storages()
      .post(storageBody)
      .then(async (res) => {
        if (isNew) {
          await message.success("This storage successfully created", 1);
          history.push(`/storages`);
          window.location.reload();
        } else {
          await message.success("This storage successfully edited", 1);
          window.location.reload();
        }
      });
  };
  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };

  const handleModalOk = async () => {
    const { data } = await api.storages(inputs.id).get();
    if (data.isDefault) {
      await message.error("Unable to delete default warehouse", 1);
      return;
    }
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api.storages(inputs.id).delete();
    await message.warning("This warehouse successfully deleted", 1);
    history.push(`/storages`);
    window.location.reload();
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleTranslateFieldsChange = (event, languageCode) => {
    event.persist();
    let data = event.target.value;

    let tmpArray = inputs[event.target.name] ? inputs[event.target.name] : [];
    tmpArray = tmpArray.filter((x) => x.languageCode !== languageCode);
    tmpArray.push({ languageCode: languageCode, text: data });
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: tmpArray,
    }));
  };

  const handleCheckBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      isActive: event.target.checked,
    }));
  };

  const handleIsDefaultChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      isDefault: event.target.checked,
    }));
  };

  const handleAreaChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      area: value,
    }));
  };

  return (
    <Tabs defaultActiveKey="1">
      {languages.map((language) => {
        return (
          <TabPane tab={language.name} key={language.id}>
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row type="flex" justify="space-between" align="top" gutter={16}>
                <Col span={12}>
                  <Form.Item label="Code">
                    <Input
                      type="text"
                      name="code"
                      onChange={handleInputChange}
                      value={inputs.code}
                    />
                  </Form.Item>
                  <Form.Item label="Warehouse name">
                    <Input
                      type="text"
                      name="name"
                      onChange={(event) =>
                        handleTranslateFieldsChange(event, language.code)
                      }
                      value={
                        inputs.name &&
                        inputs.name.some(
                          (x) => x.languageCode === language.code
                        )
                          ? inputs.name.find(
                              (x) => x.languageCode === language.code
                            ).text
                          : ""
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Address">
                    <Input
                      type="text"
                      name="address"
                      onChange={(event) =>
                        handleTranslateFieldsChange(event, language.code)
                      }
                      value={
                        inputs.address &&
                        inputs.address.some(
                          (x) => x.languageCode === language.code
                        )
                          ? inputs.address.find(
                              (x) => x.languageCode === language.code
                            ).text
                          : ""
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Manager">
                    <Input
                      name="manager"
                      type="text"
                      onChange={handleInputChange}
                      value={inputs.manager}
                    />
                  </Form.Item>
                  <Form.Item label="Area m2">
                    <InputNumber
                      name="area"
                      onChange={handleAreaChange}
                      value={inputs.area}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={inputs.isActive}
                      defaultChecked={true}
                      onChange={handleCheckBoxChange}
                    >
                      Is Active
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={inputs.isDefault}
                      defaultChecked={false}
                      onChange={handleIsDefaultChange}
                    >
                      Is Default
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
              </Row>
              <Divider />
              <Row type="flex" gutter={32}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {isNew ? "Create" : "Update"}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  {isNew ? (
                    ""
                  ) : (
                    <Form.Item>
                      <Button type="danger" onClick={showModal}>
                        Delete
                      </Button>
                      <Modal
                        title="Are you sure?"
                        visible={modal.visible}
                        onOk={handleModalOk}
                        confirmLoading={modal.confirmLoading}
                        onCancel={handleModalCancel}
                      >
                        <p>{modal.ModalText}</p>
                      </Modal>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Form>
          </TabPane>
        );
      })}
    </Tabs>
  );
};

const Storage = () => {
  const [storage, setStorage] = useState({});
  const [spinning, setSpinning] = useState(true);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isNew = params.get("isNew") === "true";

  useEffect(() => {
    const getStorage = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let storageID = params.get("storageID");
      const res = await api.storages(storageID).get();
      setStorage(res.data);
      setSpinning(false);
    };
    getStorage();
  }, []);

  return (
    <div className="storage">
      <Link className="back-icon" to="/storages">
        <ArrowLeftOutlined />
      </Link>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <Row>
              <Col span={12}>
                <div>
                  <h1>{isNew ? "Add New" : ""}</h1>
                  <Divider />
                  {isNew ? (
                    <StorageForm storage={storage} isNew={isNew} />
                  ) : (
                    !!storage.id && (
                      <StorageForm storage={storage} isNew={isNew} />
                    )
                  )}
                </div>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Storage;
