import React, { useState, useEffect } from "react";
import { Table, Layout, Row, Col, Input, Select, Divider } from "antd";
import api from "../../Api";
import languages from "../Languages/languages.json";
import { StorageName } from "../Utils/Common";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const Remainders = () => {
  const [remainders, setRemainders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [categories, setCategories] = useState([]);
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    const getRemainders = async () => {
      setLoading(true);
      await api
        .remainders()
        .get({params: {storage: selectedFilters.storage}})
        .then((res) => {
          let tmpProducts = res.data;
          if (
            selectedFilters.category &&
            selectedFilters.category !== undefined
          ) {
            tmpProducts = tmpProducts.filter(
              (x) => x.categoryId === selectedFilters.category
            );
          }
          if (selectedFilters.search) {
            tmpProducts = tmpProducts.filter(
              (product) =>
                product.name &&
                product.name
                  .find((x) => x.languageCode === languages[0].code)
                  .text.toUpperCase()
                  .includes(selectedFilters.search.toUpperCase())
            );
          }
          setRemainders(tmpProducts);
          setLoading(false);
        });
    };

    const getCategories = async () => {
      const { data } = await api.categories("isActive").get();
      setCategories(data);
    };

    const getStorages = async () => {
      await api
        .storages()
        .get()
        .then((response) => {
          setStorages(response.data);
        })
        .catch((err) => console.log(err));
    };
    getRemainders();
    getCategories();
    getStorages();
  }, [selectedFilters]);

  const handleOnSearch = (value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      search: value,
    }));
  };

  const handleCategoryChange = async (value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      category: value,
    }));
  };

  const handleStorageSelect = (storageId) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      storage: storageId,
    }));
  };

  const columns = [
    {
      title: "Warehouse code",
      dataIndex: "storageCode",
      sorter: (a, b) => a.storageCode - b.storageCode,
    },
    {
      title: "Warehouse name",
      dataIndex: "storageName",
      render: (storageName) => (
        <>
          {storageName &&
          storageName.some((x) => x.languageCode === languages[0].code)
            ? storageName.find((x) => x.languageCode === languages[0].code).text
            : ""}
        </>
      ),
    },
    {
      title: "Product code",
      dataIndex: "productCode",
      sorter: (a, b) => a.productCode - b.productCode,
    },
    {
      title: "Product name",
      dataIndex: "productName",
      render: (productName) => (
        <>
          {productName &&
          productName.some((x) => x.languageCode === languages[0].code)
            ? productName.find((x) => x.languageCode === languages[0].code).text
            : ""}
        </>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
  ];

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <h1>Remainders</h1>
      <div
        className="products-list"
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "left",
          borderRadius: 12,
        }}
      >
        <Row type="flex" justify="start" align="middle" gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
            <Search
              type="text"
              name="search"
              enterButton="Search"
              placeholder="input product name"
              onSearch={handleOnSearch}
            />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }} lg={{ span: 8 }}>
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleCategoryChange}
              placeholder="Please select category"
            >
              {categories.map((cat) => (
                <Option value={cat.id} key={cat.id}>
                  {cat.name &&
                  cat.name.some((x) => x.languageCode === languages[0].code)
                    ? cat.name.find((x) => x.languageCode === languages[0].code)
                        .text
                    : ""}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }} lg={{ span: 8 }}>
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select storage"
              onChange={(value) => handleStorageSelect(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {storages.map((storage) => (
                <Option value={storage.id} key={storage.id}>
                  {StorageName(storage)}
                </Option>
              ))}
            </Select>
          </Col>
          <Divider />
        </Row>
        <Table
          rowKey="id"
          columns={columns}
          loading={loading}
          dataSource={remainders}
          tableLayout="fixed"
          pagination={{
            onChange: (page) => {
              localStorage.setItem("currentRemainderPage", page);
            },
            defaultCurrent:
              JSON.parse(localStorage.getItem("currentRemainderPage")) ?? 1,
          }}
        />
      </div>
    </Content>
  );
};

export default Remainders;
