import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Input,
  Row,
  Button,
  Layout,
  Col,
  message,
  Divider,
  Tabs,
  Modal,
  Spin,
  Form,
} from "antd";
import languages from "../Languages/languages.json";
import api from "../../Api";
import PageEditor from "../PageEditor";
import { ValidateToken } from "../Utils/Validator";

const { Content } = Layout;
const { TabPane } = Tabs;

//Promotion component
const Promotion = () => {
  const [promotion, setPromotion] = useState({});
  const [spinning, setSpinning] = useState(true);
  const [description, setDescription] = useState([]);

  useEffect(() => {
    const fetchPromotions = async () => {
      await api
        .promotions()
        .get()
        .then((res) => {
          if (res.data.length > 0) {
            setPromotion(res.data[0]);
            setDescription(res.data[0].description);
          }
          setSpinning(false);
        })
        .catch((err) => console.log(err));
    };
    fetchPromotions();
  }, []);

  const [modal, setModal] = useState({
    ModalText: "Confirm promotion delete",
    visible: false,
    confirmLoading: false,
  });

  const handleSubmit = async () => {
    ValidateToken(history);

    //post promotion data
    let data = {
      id: 1,
      type: 0,
      title: promotion.title,
      description: description,
    };
    await api
      .promotions()
      .post(data)
      .then(async (res) => {
        if (res.data && res.data.error) {
          await message.error(res.data.message, 2);
        } else {
          await message.success("This promotion successfully edited", 1);
          window.location.reload();
        }
      });
  };
  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);

    await api.promotions(promotion.id).delete();
    await message.warning("This promotion successfully deleted", 1);
    history.push(`/prmotions`);
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  const handleTranslateFieldsChange = (event, languageCode) => {
    event.persist();
    let data = event.target.value;

    let tmpArray = promotion[event.target.name]
      ? promotion[event.target.name]
      : [];
    tmpArray = tmpArray.filter((x) => x.languageCode !== languageCode);
    tmpArray.push({ languageCode: languageCode, text: data });
    setPromotion((promotion) => ({
      ...promotion,
      [event.target.name]: tmpArray,
    }));
  };

  return (
    <div className="promotion">
      <Link className="back-icon" to="/prmotions">
        <ArrowLeftOutlined />
      </Link>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>Pop-up promotion</h1>
            <Divider />
            <Tabs defaultActiveKey="1">
              {languages.map((language) => {
                return (
                  <TabPane tab={language.name} key={language.id}>
                    <Form layout="vertical" onFinish={handleSubmit}>
                      <Form.Item label="Title">
                        <Input
                          type="text"
                          name="title"
                          onChange={(event) =>
                            handleTranslateFieldsChange(event, language.code)
                          }
                          value={
                            promotion.title &&
                            promotion.title.some(
                              (x) => x.languageCode === language.code
                            )
                              ? promotion.title.find(
                                  (x) => x.languageCode === language.code
                                ).text
                              : ""
                          }
                        />
                      </Form.Item>
                      <Row type="flex" justify="center" align="middle">
                        <Form.Item
                          label="Description"
                          style={{ width: "100%" }}
                        >
                          <PageEditor
                            description={promotion.description}
                            setDescription={setDescription}
                            languageCode={language.code}
                          />
                        </Form.Item>
                        <Divider />
                        <Row type="flex" gutter={32}>
                          <Col>
                            <Form.Item>
                              <Button type="primary" htmlType="submit">
                                Update
                              </Button>
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item>
                              <Button type="danger" onClick={showModal}>
                                Delete
                              </Button>
                              <Modal
                                title="Are you sure?"
                                visible={modal.visible}
                                onOk={handleModalOk}
                                confirmLoading={modal.confirmLoading}
                                onCancel={handleModalCancel}
                              >
                                <p>{modal.ModalText}</p>
                              </Modal>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form>
                  </TabPane>
                );
              })}
            </Tabs>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Promotion;
