import React from "react";
import { Select, Form, Input, Col, Row, Tabs, Divider } from "antd";
import Languages from "../Languages/languages.json";
import { MailDescriptionEditor } from "./../Editor";

const { Option } = Select;
const { TabPane } = Tabs;

const MailSettings = ({ settings, setSettings }) => {

  const handleChange = (value) => {
    setSettings((settings) => ({
      ...settings,
      mailSettings: { ...settings.mailSettings, defaultLanguage: value },
    }));
  };

  const handleMailChange = (e) => {
    e.persist();
    setSettings((settings) => ({
      ...settings,
      mailSettings: { ...settings.mailSettings, defaultMail: e.target.value },
    }));
    if (settings.mailSettings && !settings.mailSettings.defaultLanguage) {
      setSettings((settings) => ({
        ...settings,
        mailSettings: { ...settings.mailSettings, defaultLanguage: "en" },
      }));
    }
  };

  const handleHostChange = (e) => {
    e.persist();
    setSettings((settings) => ({
      ...settings,
      mailSettings: {
        ...settings.mailSettings,
        [e.target.name]: e.target.value,
      },
    }));
  };

  return (
    <Row
      justify="space-between"
      align="top"
      style={{ width: "100%" }}
      gutter={16}
    >
      <Col span={12}>
        <h2>Business Outgoing</h2>
        <Divider/>
        <Form.Item label="Default email language">
          <Select
            style={{ width: 200 }}
            value={
              settings.mailSettings && settings.mailSettings.defaultLanguage
                ? settings.mailSettings.defaultLanguage
                : "en"
            }
            onChange={handleChange}
          >
            {Languages.map((language) => (
              <Option key={language.id} value={language.code}>
                {language.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Comma-Separated Emails">
          <Input
            value={
              settings.mailSettings ? settings.mailSettings.defaultMail : ""
            }
            onChange={handleMailChange}
          />
        </Form.Item>
        <h2>Customer outgoing</h2>
        <Divider/>
        <Form.Item label="Description of outgoing mail to customer">
        <Tabs defaultActiveKey="1">
          {Languages.map((language) => (
            <TabPane tab={language.name} key={language.id}>
              <MailDescriptionEditor
                description={settings.mailSettings && settings.mailSettings.description ? settings.mailSettings.description : []}
                setSettings={setSettings}
                languageCode={language.code}
              />
            </TabPane>
          ))}
        </Tabs>
        </Form.Item>
      </Col>
      <Col span={12}>
        <h2>Mail server settings</h2>
        <Form.Item label="Host address">
          <Input
            name="hostAddress"
            value={
              settings.mailSettings && settings.mailSettings.hostAddress ? settings.mailSettings.hostAddress : ""
            }
            onChange={handleHostChange}
          />
        </Form.Item>
        <Form.Item label="Host port">
          <Input
            name="hostPort"
            value={settings.mailSettings && settings.mailSettings.hostPort  ? settings.mailSettings.hostPort : ""}
            onChange={handleHostChange}
          />
        </Form.Item>
        <Form.Item label="Host user">
          <Input
            name="hostUser"
            value={settings.mailSettings && settings.mailSettings.hostUser ? settings.mailSettings.hostUser : ""}
            onChange={handleHostChange}
          />
        </Form.Item>
        <Form.Item
          label="Host password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            name="hostPassword"
            value={
              settings.mailSettings && settings.mailSettings.hostPassword ? settings.mailSettings.hostPassword : ""
            }
            onChange={handleHostChange}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default MailSettings;
