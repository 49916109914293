import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Row,
  Input,
  Button,
  Layout,
  Col,
  message,
  Tabs,
  Divider,
  Modal,
  Spin,
  Upload,
  Form,
} from "antd";
import languages from "../Languages/languages.json";
import slugify from "slugify";
import api from "../../Api";

const { Content } = Layout;
const { TextArea } = Input;
const { TabPane } = Tabs;
const API_URL = process.env.REACT_APP_API_URL;

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const ManufacturerForm = ({ manufacturer, isNew }) => {
  const [inputs, setInputs] = useState(
    isNew
      ? {
          name: [],
          description: [],
          imageUrl: "",
          slug: "",
        }
      : manufacturer
  );

  const [modal, setModal] = useState({
    ModalText: "Confirm manufacturer delete",
    visible: false,
    confirmLoading: false,
  });

  const [images, setImages] = useState({
    fileList:
      manufacturer && manufacturer.imageUrl
        ? [
            {
              uid: manufacturer ? manufacturer : -1,
              name: "image",
              status: "done",
              url: `${manufacturer.imageUrl ? manufacturer.imageUrl : ""}`,
            },
          ]
        : [],
  });

  const handleSubmit = () => {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let manufacturerID = params.get("manufacturerID");

    let formData = new FormData();
    for (let i = 0; i < images.fileList.length; i++) {
      formData.append("image", images.fileList[i].originFileObj);
    }
    api
      .uploads()
      .post(formData)
      .then(async (response) => {
        response.data.forEach((res) => {
          api.manufacturers().post({
            id: parseInt(manufacturerID),
            imageUrl: `${API_URL}/${res.path}`,
          });
        });
      });

    let data = {
      id: parseInt(manufacturerID),
      code: inputs.code,
      name: inputs.name,
      description: inputs.description,
      imageUrl: images.fileList
        ? images.fileList.length === 0
          ? ""
          : images.fileList.url
        : "",
      slug: slugify(inputs.name[0].text, {
        lower: true,
      }),
    };
    if (isNew) {
      data = { ...data, createdAt: new Date() };
    }
    api
      .manufacturers()
      .post(data)
      .then(async (res) => {
        if (isNew) {
          await message.success("This manufacturer successfully created", 1);
          history.push(`/manufacturers`);
          window.location.reload();
        } else {
          await message.success("This manufacturer successfully edited", 1);
          window.location.reload();
        }
      });
  };
  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api.manufacturers(inputs.id).delete();
    await message.warning("This manufacturer successfully deleted", 1);
    history.push(`/manufacturers`);
    window.location.reload();
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };
  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleTranslateFieldsChange = (event, languageCode) => {
    event.persist();
    let data = event.target.value;

    let tmpArray = inputs[event.target.name] ? inputs[event.target.name] : [];
    tmpArray = tmpArray.filter((x) => x.languageCode !== languageCode);
    tmpArray.push({ languageCode: languageCode, text: data });
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: tmpArray,
    }));
  };

  const handleImageChange = (img) => {
    setImages((images) => ({ ...images, fileList: img.fileList }));
  };

  const uploadButton = (
    <div>
      {images.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
    }));
  };

  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  return (
    <Tabs defaultActiveKey="1">
      {languages.map((language) => {
        return (
          <TabPane tab={language.name} key={language.id}>
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row type="flex" justify="space-between" align="top" gutter={16}>
                <Col span={12}>
                  <Form.Item label="Code">
                    <Input
                      type="text"
                      name="code"
                      onChange={handleInputChange}
                      value={inputs.code}
                    />
                  </Form.Item>
                  <Form.Item label="Manufacturer name">
                    <Input
                      type="text"
                      name="name"
                      onChange={(event) =>
                        handleTranslateFieldsChange(event, language.code)
                      }
                      value={
                        inputs.name &&
                        inputs.name.some(
                          (x) => x.languageCode === language.code
                        )
                          ? inputs.name.find(
                              (x) => x.languageCode === language.code
                            ).text
                          : ""
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Description">
                    <TextArea
                      type="text"
                      name="description"
                      autosize={{ minRows: 3, maxRows: 50 }}
                      onChange={(event) =>
                        handleTranslateFieldsChange(event, language.code)
                      }
                      value={
                        inputs.description &&
                        inputs.description.some(
                          (x) => x.languageCode === language.code
                        )
                          ? inputs.description.find(
                              (x) => x.languageCode === language.code
                            ).text
                          : ""
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Slug">
                    <Input
                      name="slug"
                      type="text"
                      onChange={handleInputChange}
                      value={inputs.slug}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <div className="clearfix">
                      <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        accept=".jpg, .jpeg, .png, .gif"
                        listType="picture-card"
                        fileList={images.fileList}
                        beforeUpload={beforeUpload}
                        onPreview={handlePreview}
                        onChange={handleImageChange}
                      >
                        {!!images.fileList && images.fileList.length >= 1
                          ? null
                          : uploadButton}
                      </Upload>
                      <Modal
                        visible={images.previewVisible}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={images.previewImage}
                        />
                      </Modal>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row type="flex" gutter={32}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {isNew ? "Create" : "Update"}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  {isNew ? (
                    ""
                  ) : (
                    <Form.Item>
                      <Button type="danger" onClick={showModal}>
                        Delete
                      </Button>
                      <Modal
                        title="Are you sure?"
                        visible={modal.visible}
                        onOk={handleModalOk}
                        confirmLoading={modal.confirmLoading}
                        onCancel={handleModalCancel}
                      >
                        <p>{modal.ModalText}</p>
                      </Modal>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Form>
          </TabPane>
        );
      })}
    </Tabs>
  );
};

const Manufacturer = () => {
  const [manufacturer, setManufacturer] = useState({});
  const [spinning, setSpinning] = useState(true);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isNew = params.get("isNew") === "true";

  useEffect(() => {
    const fetchManufacturer = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let manufacturerID = params.get("manufacturerID");
      const res = await api.manufacturers(manufacturerID).get();
      setManufacturer(res.data[0]);
      setSpinning(false);
    };
    fetchManufacturer();
  }, []);
  return (
    <div className="manufacturer">
      <Link className="back-icon" to="/manufacturers">
        <ArrowLeftOutlined />
      </Link>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <Row>
              <Col span={12}>
                <div>
                  <h1>{isNew ? "Add New" : ""}</h1>
                  <Divider />
                  {isNew ? (
                    <ManufacturerForm
                      manufacturer={manufacturer}
                      isNew={isNew}
                    />
                  ) : (
                    !!manufacturer.id && (
                      <ManufacturerForm
                        manufacturer={manufacturer}
                        isNew={isNew}
                      />
                    )
                  )}
                </div>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Manufacturer;
