import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Row,
  Input,
  Button,
  Layout,
  Col,
  message,
  Divider,
  Modal,
  Spin,
  Select,
  Checkbox,
  InputNumber,
  Form,
} from "antd";
import api from "../../Api";
import languages from "../Languages/languages.json";

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const DiscountForm = ({ defaultValues, isNew }) => {
  const [inputs, setInputs] = useState(
    isNew
      ? {
          name: "",
          description: "",
        }
      : defaultValues
  );
  const [modal, setModal] = useState({
    ModalText: "Confirm discount document delete",
    visible: false,
    confirmLoading: false,
  });
  const [categories, setCategories] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [allDiscounts, setAllDiscounts] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [spinning, setSpinning] = useState(true);
  useEffect(() => {
    const getDiscounts = async () => {
      const { data } = await api.discounts(inputs.customerUid).get();
      setDiscounts(data);
    };
    const getAllDiscounts = async () => {
      const { data } = await api.discounts().get();
      setAllDiscounts(data);
    };
    if (inputs.customerUid) {
      getDiscounts();
    } else {
      getAllDiscounts();
    }
    setSpinning(false);
  }, [inputs.customerUid]);

  useEffect(() => {
    const getProducts = async () => {
      const { data } = await api.products().get();
      setProducts(data);
    };
    const getCategories = async () => {
      const { data } = await api.categories("All").get();
      setCategories(data);
    };
    const getCustomers = async () => {
      const { data } = await api.getCustomers().get();
      if (isNew) {
        for (let j = 0; j < allDiscounts.length; j++) {
          if (data.some((x) => x.id === allDiscounts[j].customerUid)) {
            let index = data.findIndex(
              (x) => x.id === allDiscounts[j].customerUid
            );
            data.splice(index, 1);
          }
        }
      }
      setCustomers(data);
    };
    getProducts();
    getCategories();
    getCustomers();
  }, [discounts, allDiscounts, isNew]);

  const handleSubmit = async (values) => {
    deleted.forEach(async (x) => {
      await api.discounts(x).delete();
    });

    //check for duplicates
    let valueArrCategories = discounts.map(function (item) {
      return item.categoryId;
    });
    let valueArrProducts = discounts.map(function (item) {
      return item.productId;
    });
    let isDuplicateCategories = valueArrCategories.some(function (item, idx) {
      return item === 0 ? false : valueArrCategories.indexOf(item) !== idx;
    });
    let isDuplicateProducts = valueArrProducts.some(function (item, idx) {
      return item === 0 ? false : valueArrProducts.indexOf(item) !== idx;
    });
    if (isDuplicateCategories || isDuplicateProducts) {
      await message.warning("Check for duplicate values", 1);
      return;
    }
    if (discounts.length === 0) {
      await message.warning("Discount grid is empty", 1);
      return;
    }
    let data = [];
    discounts.map(async (row) => {
      if (
        row.type === "" ||
        (row.categoryId === "" && row.productId === "") ||
        (row.productId === "" && row.categoryId === "")
      ) {
        data = [];
        await message.warning("Check for empty fields", 1);
        return;
      }
      data.push({
        id: row.id,
        name: values.name,
        description: values.description,
        customerUid: values.customerUid,
        type: row.type,
        categoryId:  row.categoryId ? row.categoryId : 0,
        productId: row.productId ? row.productId : 0,
        discount: row.discount,
        isActive: row.isActive,
      });
    });
    data.forEach(async (row) => {
      await api
        .discounts()
        .post(row)
        .then(async (res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    });
    if (data.length > 0) {
      if (isNew) {
        await message.success("This discount document successfully created", 1);
        history.push(`/discounts`);
        window.location.reload();
      } else {
        await message.success("This discount document successfully edited", 1);
        window.location.reload();
      }
    }
  };
  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);

    await api.discounts(inputs.customerUid).delete();
    await message.warning("This discount document successfully deleted", 1);
    history.push(`/discounts`);
  };
  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  const handleCustomerChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      customerUid: value,
    }));
  };

  const addNewRow = () => {
    setDiscounts((prevRows) => [
      ...prevRows,
      { type: "1", categoryId: "", productId: "", discount: 0, isActive: true },
    ]);
  };

  const handleRemoveSpecificRow = (index, id) => async () => {
    discounts.splice(index, 1);

    setDeleted([...deleted, id]);
    setDiscounts((discounts) => [...discounts]);
  };

  const handleTypeChange = (value, index) => {
    const _tempRows = [...discounts];
    _tempRows[index].type = value;
    setDiscounts(_tempRows);
  };

  const handleCategoryChange = (value, index) => {
    const _tempRows = [...discounts];
    _tempRows[index].productId = "";
    _tempRows[index].categoryId = value;
    setDiscounts(_tempRows);
    categories.splice(value, 1);
  };

  const handleProductChange = (value, index) => {
    const _tempRows = [...discounts];
    _tempRows[index].categoryId = "";
    _tempRows[index].productId = value;
    setDiscounts(_tempRows);
  };

  const handleDiscountChange = (value, index) => {
    const _tempRows = [...discounts];
    _tempRows[index].discount = value;
    setDiscounts(_tempRows);
  };

  const handleIsActiveChange = (e, index) => {
    const _tempRows = [...discounts];
    _tempRows[index].isActive = e.target.checked;
    setDiscounts(_tempRows);
  };

  return (
    <Form
      initialValues={{
        customerUid: inputs.customerUid,
        name: inputs.name,
        description: inputs.description,
      }}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Row type="flex" justify="start" align="middle" gutter={16}>
        <Col span={12}>
          <Form.Item
            name="customerUid"
            label="Customer"
            rules={[{ required: true, message: "Please select customer" }]}
          >
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Please select customer"
              onChange={handleCustomerChange}
              value={inputs.customerUid}
              disabled={isNew ? false : true}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {customers.map((customer) => (
                <Option value={customer.id} key={customer.id}>
                  {customer.businessName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input discount name" }]}
          >
            <Input value={inputs.name} />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <TextArea
              autosize={{ minRows: 3, maxRows: 50 }}
              value={inputs.description}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="center" align="middle">
        <Spin spinning={spinning} delay={300}>
          <table>
            {discounts && discounts.length > 0 ? (
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ opacity: 0 }}>id</th>
                  <th>Type</th>
                  <th>Category</th>
                  <th>Product</th>
                  <th>Discount (%)</th>
                  <th>Is Active</th>
                </tr>
              </thead>
            ) : (
              <thead></thead>
            )}
            <tbody>
              {discounts.map((discount, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Input hidden disabled value={discount.id} />
                  </td>
                  <td>
                    <Select
                      style={{ width: 200 }}
                      value={discount.type}
                      onChange={(value) => handleTypeChange(value, index)}
                    >
                      <Option value="1">By category</Option>
                      <Option value="2">By product</Option>
                    </Select>
                  </td>
                  <td>
                    <Select
                      showSearch
                      disabled={
                        discount.type === "" || discount.type === "2"
                          ? true
                          : false
                      }
                      style={{ width: 200 }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={discount.categoryId}
                      onChange={(value) => handleCategoryChange(value, index)}
                      placeholder="Please select category"
                    >
                      {categories.map((cat) => (
                        <Option value={cat.id} key={cat.id}>
                          {cat.name &&
                          cat.name.some(
                            (x) => x.languageCode === languages[0].code
                          )
                            ? cat.name.find(
                                (x) => x.languageCode === languages[0].code
                              ).text
                            : ""}
                        </Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Select
                      showSearch
                      disabled={
                        discount.type === "" || discount.type === "1"
                          ? true
                          : false
                      }
                      style={{ width: 400 }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={discount.productId}
                      onChange={(value) => handleProductChange(value, index)}
                    >
                      {products.map((product) => (
                        <Option value={product.id} key={product.id}>
                          {product.name &&
                          product.name.some(
                            (x) => x.languageCode === languages[0].code
                          )
                            ? product.name.find(
                                (x) => x.languageCode === languages[0].code
                              ).text
                            : ""}
                        </Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <InputNumber
                      defaultValue={0}
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      name="discount"
                      onChange={(value) => handleDiscountChange(value, index)}
                      value={discount.discount}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Checkbox
                      checked={discount.isActive}
                      onChange={(value) => handleIsActiveChange(value, index)}
                      defaultChecked
                    />
                  </td>
                  <td>
                    <Button
                      type="danger"
                      shape="circle"
                      onClick={handleRemoveSpecificRow(index, discount.id)}
                    >
                      -
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Row type="flex" justify="center" align="middle">
            <Button shape="circle" onClick={addNewRow}>
              +
            </Button>
          </Row>
        </Spin>
      </Row>
      <Divider />
      <Row justify="center" align="middle" type="flex" gutter={32}>
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isNew ? "Create" : "Update"}
            </Button>
          </Form.Item>
        </Col>
        <Col>
          {isNew ? (
            ""
          ) : (
            <Form.Item>
              <Button type="danger" onClick={showModal}>
                Delete
              </Button>
              <Modal
                title="Are you sure?"
                visible={modal.visible}
                onOk={handleModalOk}
                confirmLoading={modal.confirmLoading}
                onCancel={handleModalCancel}
              >
                <p>{modal.ModalText}</p>
              </Modal>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

const Discount = () => {
  const [discountDoc, setDiscountDoc] = useState({});
  const [spinning, setSpinning] = useState(true);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isNew = params.get("isNew") === "true";

  useEffect(() => {
    const fetchDiscount = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let discountCustomer = params.get("discountCustomer");

      await api
        .discounts(discountCustomer)
        .get()
        .then((res) => {
          setDiscountDoc(res.data[0]);
          setSpinning(false);
        })
        .catch((err) => console.log(err));
    };
    fetchDiscount();
  }, []);

  return (
    <div className="discount">
      <Link className="back-icon" to="/discounts">
        <ArrowLeftOutlined />
      </Link>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>{isNew ? "Add New" : ""}</h1>
            <Divider />
            {isNew ? (
              <DiscountForm defaultValues={discountDoc} isNew={isNew} />
            ) : (
              !!discountDoc.id && (
                <DiscountForm defaultValues={discountDoc} isNew={isNew} />
              )
            )}
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Discount;
