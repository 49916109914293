import React, { useState, useEffect } from "react";
import {
  Row,
  Divider,
  Layout,
  Spin,
  Col,
  DatePicker,
  message,
  List,
  Button,
  Drawer,
  Modal,
} from "antd";
import api from "../../Api";
import InputDetails from "./InputDetails";
import { PlusOutlined } from "@ant-design/icons";
import NewInput from "./NewInput";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const Inputs = () => {
  const [inputs, setInputs] = useState([]);
  const [spinning, setSpinning] = useState(true);
  const [inputDrawer, setinputDrawer] = useState((inputDrawer) => ({
    ...inputDrawer,
    visible: false,
  }));
  const [selectedDate, setSelectedDate] = useState();
  const [newInput, setNewInput] = useState({ inputDetails: [] });

  const [modal, setModal] = useState({
    ModalText: "Confirm input delete",
    visible: false,
    confirmLoading: false,
  });
  const [newInputModal, setNewInputModal] = useState({
    ModalText: "Add new input",
    visible: false,
    confirmLoading: false,
  });

  const onDateRangeChange = async (date, dateString) => {
    setSelectedDate(dateString);
  };

  useEffect(() => {
    const getInputs = async () => {
      await api
        .documents()
        .get({
          params: { type: 5 },
        })
        .then((response) => {
          let tempinputs = [];
          tempinputs = response.data;
          if (selectedDate && selectedDate[0] !== "") {
            let dateOne = Date.parse(selectedDate[0]);
            let dateTwo =
              selectedDate[0] !== selectedDate[1]
                ? Date.parse(selectedDate[1])
                : Date.parse(selectedDate[1]) + 86400000;
            tempinputs = tempinputs.filter(
              (x) =>
                Date.parse(x.date) > dateOne && Date.parse(x.date) < dateTwo
            );
          }
          setInputs(tempinputs);
          setSpinning(false);
        });
    };

    getInputs();
  }, [selectedDate]);

  const showInputDrawer = (inputUid) => {
    setinputDrawer({
      inputUid: inputUid,
      visible: true,
    });
  };

  const closeInputDrawer = (inputUid) => {
    setinputDrawer({
      inputUid: inputUid,
      visible: false,
    });
  };

  //input delete modal
  const showDeleteModal = (inputUid) => {
    setModal((modal) => ({
      ...modal,
      visible: true,
      inputUid: inputUid,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api
      .documents()
      .delete({
        params: { Uid: modal.inputUid, type: 5 },
      })
      .then((res) => console.log(res.data));
    await message.warning(`input N ${modal.inputUid} successfully deleted`, 1);
    window.location.reload();
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  //New input modal
  const showNewInputModal = () => {
    setNewInputModal((newinputModal) => ({
      ...newinputModal,
      visible: true,
    }));
  };

  const handleNewInputModalOk = async () => {
    setNewInputModal((newInputModal) => ({
      ...newInputModal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setNewInputModal((newInputModal) => ({
        ...newInputModal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);

    //store new input
    let total = newInput.inputDetails.reduce(
      (a, b) => a + b.price * b.quantity,
      0
    );
    const documentData = {
      notes: newInput.notes,
      type: 5,
      totalSum: Number(total.toFixed(2)),
    };
    await api
      .documents()
      .post(documentData)
      .then(async (response) => {
        const inputsBody = [];
        newInput.inputDetails.forEach((row) => {
          inputsBody.push({
            inputUid: response.data.id,
            productId: row.productId,
            storageId: row.storageId,
            quantity: row.quantity,
            price: row.price,
          });
        });
        await api
          .inputs()
          .post(inputsBody)
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
    await message.success(`New Input added`, 1);
    window.location.reload();
  };

  const handleNewInputModalCancel = () => {
    setNewInputModal((newInputModal) => ({
      ...newInputModal,
      visible: false,
    }));
  };

  return (
    <div className="inputs">
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>Inputs</h1>
            <Row type="flex" justify="space-between" align="middle" gutter={32}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}></Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <RangePicker onChange={onDateRangeChange} />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 6 }}
                style={{ textAlign: "end" }}
              >
                <Button
                  type="primary"
                  className="green-btn"
                  onClick={showNewInputModal}
                  icon={<PlusOutlined />}
                >
                  Add new
                </Button>
                <Modal
                  title={`Add new input`}
                  width="90%"
                  visible={newInputModal.visible}
                  maskClosable={false}
                  onOk={handleNewInputModalOk}
                  onCancel={handleNewInputModalCancel}
                  footer={[
                    <Button key="back" onClick={handleNewInputModalCancel}>
                      Cancel
                    </Button>,
                    <Button
                      className="green-btn"
                      key="submit"
                      type="primary"
                      loading={newInputModal.confirmLoading}
                      onClick={handleNewInputModalOk}
                      disabled={
                        newInput.inputDetails === undefined ||
                        (newInput.inputDetails &&
                          newInput.inputDetails.length === 0) ||
                        (newInput.inputDetails &&
                          newInput.inputDetails.some(
                            (x) => x.productId === 0 || x.storageId === 0
                          ))
                      }
                    >
                      Add new Input
                    </Button>,
                  ]}
                >
                  <NewInput newInput={newInput} setNewInput={setNewInput} />
                </Modal>
              </Col>
            </Row>
            <Divider />
            <Row type="flex" justify="center" align="middle">
              <List
                style={{ width: "100%" }}
                itemLayout="horizontal"
                loading={spinning}
                binputed="true"
                pagination={{
                  onChange: (page) => {
                    localStorage.setItem("inputCurrentPage", page);
                  },
                  defaultCurrent: JSON.parse(
                    localStorage.getItem("inputCurrentPage")
                  ) ?? 1,
                }}
                dataSource={inputs.sort(
                  (a, b) => new Date(b.date) - new Date(a.date)
                )}
                renderItem={(input) => (
                  <List.Item key={input.id}>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={32}
                      style={{ width: "100%" }}
                    >
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <List.Item.Meta
                          title=""
                          description={
                            input.totalSum + " € - input N: " + input.id
                          }
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 3 }} lg={{ span: 3 }}>
                        <Row type="flex" justify="space-between" align="middle">
                          <div className="input-date">
                            {new Date(input.date).getFullYear() +
                              "-" +
                              (new Date(input.date).getMonth() + 1) +
                              "-" +
                              new Date(input.date).getDate()}
                          </div>
                        </Row>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 3 }}
                        lg={{ span: 3 }}
                      ></Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 6 }}
                        lg={{ span: 6 }}
                        style={{ textAlign: "right" }}
                      >
                        <Button onClick={() => showInputDrawer(input.id)}>
                          Show
                        </Button>
                        <Button
                          type="primary"
                          style={{ marginLeft: 4 }}
                          danger
                          onClick={() => showDeleteModal(input.id)}
                        >
                          Delete
                        </Button>
                      </Col>
                      <Modal
                        title={`Are you sure delete input N ${modal.inputUid}?`}
                        visible={modal.visible}
                        onOk={handleModalOk}
                        confirmLoading={modal.confirmLoading}
                        onCancel={handleModalCancel}
                      >
                        <p>{modal.ModalText}</p>
                      </Modal>
                      <Drawer
                        title="View input"
                        width={window.innerWidth < 576 ? "100%" : "75%"}
                        closable={true}
                        onClose={() => closeInputDrawer(input.id)}
                        visible={
                          inputDrawer.visible &&
                          inputDrawer.inputUid === input.id
                        }
                      >
                        <InputDetails input={input} />
                      </Drawer>
                    </Row>
                  </List.Item>
                )}
              />
            </Row>
            <Divider />
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Inputs;
