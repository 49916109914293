import React, { useState, useEffect } from "react";
import { Row, Col, Select, Input, InputNumber, List, Button } from "antd";
import { ProductName, StorageName } from "../Utils/Common";
import api from "../../Api";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const NewInput = ({ newInput, setNewInput }) => {
  const [products, setProducts] = useState([]);
  const [storages, setStorages] = useState([]);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((response) => {
          setProducts(response.data);
        })
        .catch((err) => console.log(err));
    };

    const getStorages = async () => {
      await api
        .storages()
        .get()
        .then((response) => {
          setStorages(response.data);
        })
        .catch((err) => console.log(err));
    };
    getProducts();
    getStorages();
  }, []);

  const setTotals = () => {
    let total = newInput.inputDetails.reduce(
      (a, b) => a + b.price * b.quantity,
      0
    );
    setTotalSum(Number(total.toFixed(2)));
  };

  const handleInputRowDelete = (index) => {
    newInput.inputDetails.splice(index, 1);
    setTotals();
    setNewInput((newInput) => ({
      ...newInput,
      inputDetails: newInput.inputDetails,
    }));
  };

  const handlePriceChange = (price, index) => {
    newInput.inputDetails[index].price = price;
    setTotals();
    setNewInput((newInput) => ({
      ...newInput,
      inputDetails: newInput.inputDetails,
    }));
  };

  const handleQuantityChange = (quantity, index) => {
    newInput.inputDetails[index].quantity = quantity;
    setTotals();
    setNewInput((newInput) => ({
      ...newInput,
      inputDetails: newInput.inputDetails,
    }));
  };

  const handleAddProduct = () => {
    newInput.inputDetails.push({
      productId: 0,
      storageId: storages.find((x) => x.isDefault).id,
      quantity: 1,
      price: 0,
    });
    setTotals();
    setNewInput((newInput) => ({
      ...newInput,
      inputDetails: newInput.inputDetails,
    }));
  };

  const handleProductSelect = (productId, index) => {
    newInput.inputDetails[index].productId = productId;
    setTotals();
    setNewInput((newInput) => ({
      ...newInput,
      inputDetails: newInput.inputDetails,
    }));
  };

  const handleStorageSelect = (storageId, index) => {
    newInput.inputDetails[index].storageId = storageId;
    setTotals();
    setNewInput((newInput) => ({
      ...newInput,
      inputDetails: newInput.inputDetails,
    }));
  };

  const handleNotesChange = (e) => {
    setNewInput((newInput) => ({
      ...newInput,
      notes: e.target.value,
    }));
  };

  return (
    <Row type="flex" justify="space-between" align="middle" gutter={32}>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 4 }}>
        Notes:
        <TextArea
          type="text"
          onChange={handleNotesChange}
          autoSize={{ minRows: 3, maxRows: 50 }}
        />
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 20 }}>
        <Button
          type="primary"
          className="green-btn"
          size="small"
          icon={<PlusOutlined />}
          onClick={handleAddProduct}
        ></Button>
        <List
          header="New input"
          style={{ width: "100%" }}
          itemLayout="horizontal"
          size="small"
          footer={"Total " + totalSum + " €"}
          dataSource={newInput.inputDetails}
          renderItem={(row, index) => (
            <List.Item key={row.id}>
              <List.Item.Meta
                title={
                  <Row
                    style={{ width: "100%" }}
                    type="flex"
                    justify="start"
                    align="middle"
                    gutter={32}
                  >
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                      <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select product"
                        onChange={(value) => handleProductSelect(value, index)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {products.map((product) => (
                          <Option value={product.id} key={product.id}>
                            {ProductName(product)}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                      <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select storage"
                        defaultValue={storages.find(x => x.isDefault).id}
                        onChange={(value) => handleStorageSelect(value, index)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {storages.map((storage) => (
                          <Option value={storage.id} key={storage.id}>
                            {StorageName(storage)}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                }
              />
              <div style={{ marginRight: 12 }}>
                Price:{" "}
                <InputNumber
                  size="small"
                  min={0}
                  onChange={(value) => handlePriceChange(value, index)}
                  value={row.price}
                />
              </div>
              <div>
                Quantity:{" "}
                <InputNumber
                  size="small"
                  min={1}
                  onChange={(value) => handleQuantityChange(value, index)}
                  value={row.quantity}
                />
              </div>
              <Button
                type="primary"
                size="small"
                style={{ marginLeft: 4 }}
                danger
                onClick={() => handleInputRowDelete(index)}
              >
                -
              </Button>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default NewInput;
