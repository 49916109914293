import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Layout, Button, Row, Col, List } from "antd";
import api from "./../../Api";
import languages from "../Languages/languages.json";

const { Content } = Layout;

const StoragesDataView = () => {
  const [storages, setStorages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStorages = async () => {
      const { data } = await api.storages().get();
      setStorages(data);
      setLoading(false)
    };
    getStorages();
  }, []);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <h1>Warehouses</h1>
        </Col>
        <Col span={12}>
          <div className="addNew">
            <Link
              to={{
                pathname: `/storage/?isNew=true`,
              }}
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="green-btn"
              >
                Add new
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "left",
          borderRadius: 12,
        }}
      >
        <List
          itemLayout="horizontal"
          loading={loading}
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 30,
          }}
          dataSource={storages}
          renderItem={(storage) => (
            <List.Item key={storage.id}>
              <h2>{storage.code}</h2>
              <List.Item.Meta
                title={
                    storage.name &&
                    storage.name.some(
                    (x) => x.languageCode === languages[0].code
                  )
                    ? storage.name.find(
                        (x) => x.languageCode === languages[0].code
                      ).text
                    : ""
                }
                description={
                    storage.address &&
                    storage.address.some(
                    (x) => x.languageCode === languages[0].code
                  )
                    ? storage.address.find(
                        (x) => x.languageCode === languages[0].code
                      ).text
                    : ""
                }
              />
              <Link
                to={{
                  pathname: `/storage/?storageID=${storage.id}`,
                }}
              >
                <Button>Show/Edit</Button>
              </Link>
            </List.Item>
          )}
        />
      </div>
    </Content>
  );
};
export default StoragesDataView;
