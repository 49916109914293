import React, { useState, useEffect } from "react";
import {
  List,
  Layout,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  message,
  Select,
  Checkbox,
} from "antd";
import api from "../../Api";
import languages from "../Languages/languages.json";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const Prices = () => {
  const [prices, setPrices] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [categories, setCategories] = useState([]);
  const [changedPrices, setChangedPrices] = useState([]);

  //get prices
  useEffect(() => {
    const getPrices = async () => {
      setLoading(true);
      await api
        .prices()
        .get()
        .then((res) => {
          setPrices(res.data);
        })
        .then(getProducts())
        .catch((error) => console.log(error));
    };
    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((res) => {
          if (selectedFilters) {
            let tmpProducts = res.data;
            if (
              selectedFilters.category &&
              selectedFilters.category !== undefined
            ) {
              tmpProducts = tmpProducts.filter(
                (x) => x.categoryId === selectedFilters.category
              );
            }
            if (selectedFilters.isActive === true) {
              tmpProducts = tmpProducts.filter((x) => x.isActive);
            }
            if (selectedFilters.search) {
              tmpProducts = tmpProducts.filter(
                (product) =>
                  product.name &&
                  product.name
                    .find((x) => x.languageCode === languages[0].code)
                    .text.toUpperCase()
                    .includes(selectedFilters.search.toUpperCase())
              );
            }
            setProducts(tmpProducts);
          }
          setLoading(false);
        })
        .catch((error) => console.log(error));
    };
    const getCategories = async () => {
      const { data } = await api.categories("isActive").get();
      setCategories(data);
    };
    getPrices();
    getCategories();
  }, [selectedFilters]);

  const handleOnSearch = (value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      search: value,
    }));
  };

  const handleCategoryChange = async (value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      category: value,
    }));
  };

  const handleisActiveChange = async (e) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      isActive: e.target.checked,
    }));
  };

  const lineStyles = (product) => {
    if (!product.isActive) {
      return {
        background:
          "linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255 231 231) 50%, rgb(255, 255, 255) 100%)",
      };
    }
  };

  const submitPrices = async () => {
    await api
      .prices()
      .post(changedPrices)
      .then(async (res) => {
        await message.success("Global prices successfully edited", 1);
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  const handlePriceChange = async (productId, value) => {
    let tmpPrices = changedPrices;
    if (prices.some((x) => x.productId === productId)) {
      if (tmpPrices.some((x) => x.productId === productId)) {
        tmpPrices.find((x) => x.productId === productId).price = Number(value);
      } else {
        tmpPrices.push({
          id: prices.find((x) => x.productId === productId).id,
          productId: productId,
          price: Number(value),
        });
      }
    } else {
      if (tmpPrices.some((x) => x.productId === productId)) {
        tmpPrices.find((x) => x.productId === productId).price = Number(value);
      } else {
        tmpPrices.push({ productId: productId, price: Number(value) });
      }
    }
    setChangedPrices(tmpPrices);
  };
  
  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={6}>
          <h1>Global Prices</h1>
        </Col>
        <Col span={6}>
          <Select
            showSearch
            allowClear
            style={{ width: 200 }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleCategoryChange}
            placeholder="Please select category"
          >
            {categories.map((cat) => (
              <Option value={cat.id} key={cat.id}>
                {cat.name &&
                cat.name.some((x) => x.languageCode === languages[0].code)
                  ? cat.name.find((x) => x.languageCode === languages[0].code)
                      .text
                  : ""}
              </Option>
            ))}
          </Select>
          <Checkbox onChange={handleisActiveChange} style={{ marginLeft: 12 }}>
            Is Active
          </Checkbox>
        </Col>
        <Col span={6}>
          <Search
            type="text"
            name="search"
            enterButton="Search"
            placeholder="input product name"
            onSearch={handleOnSearch}
          />
        </Col>
        <Col span={6}></Col>
      </Row>
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "left",
          borderRadius: 12,
        }}
      >
        <List
          itemLayout="horizontal"
          loading={loading}
          pagination={{
            onChange: (page) => {
              localStorage.setItem("currentPage", page);
            },
            defaultCurrent: JSON.parse(localStorage.getItem("currentPage")),
          }}
          dataSource={products}
          renderItem={(product) => (
            <List.Item key={product.id} style={lineStyles(product)}>
              <h2>{product.code}</h2>
              <List.Item.Meta
                title={
                  product.name &&
                  product.name.some((x) => x.languageCode === languages[0].code)
                    ? product.name.find(
                        (x) => x.languageCode === languages[0].code
                      ).text
                    : ""
                }
              />
              <InputNumber
                min={0}
                defaultValue={
                  prices.some((x) => x.productId === product.id)
                    ? prices.find((x) => x.productId === product.id).price
                    : 0
                }
                onChange={(value) => handlePriceChange(product.id, value)}
              />
              <span style={{ marginLeft: 6 }}>€</span>
            </List.Item>
          )}
        />
        <Row type="flex" justify="center" align="middle">
          <Button type="primary" onClick={submitPrices}>
            Update
          </Button>
        </Row>
      </div>
    </Content>
  );
};
export default Prices;
