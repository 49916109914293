import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { List, Layout, Button, Row, Col, Input } from "antd";
import api from "../../Api";
import languages from "../Languages/languages.json";

const { Content } = Layout;
const { Search } = Input;

const PagesDataView = () => {
  const [pages, setPages] = useState([]);
  const [nextId, setNextId] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPages = async () => {
      const res = await api.pages().get();
      setNextId(
        Math.max.apply(
          Math,
          res.data.map(function (o) {
            return o.id;
          })
        ) + 1
      );
      setPages(res.data);
      setLoading(false);
    };
    getPages();
  }, []);

  const handleOnSearch = (value) => {
    api
      .pages()
      .get()
      .then((res) => {
        if (!value) {
          setPages(res.data);
        } else {
          setPages(
            pages.filter(
              (page) =>
                page.title &&
                page.title
                  .find((x) => x.languageCode === languages[0].code)
                  .text.toUpperCase()
                  .includes(value.toUpperCase())
            )
          );
        }
      });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={6}>
          <h1>Pages</h1>
        </Col>
        <Col span={12}>
          <Search
            type="text"
            name="search"
            enterButton="Search"
            placeholder="input search text"
            onSearch={handleOnSearch}
          />
        </Col>
        <Col span={6}>
          <div className="addNew">
            <Link to={{ pathname: `/page/?pageID=${nextId}&isNew=true` }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="green-btn"
              >
                Add new
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "left",
          borderRadius: 12,
        }}
      >
        <List
          itemLayout="horizontal"
          loading={loading}
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
          }}
          dataSource={pages}
          renderItem={(page) => (
            <List.Item key={page.id}>
              <List.Item.Meta
                title={
                  page.title &&
                  page.title.some((x) => x.languageCode === languages[0].code)
                    ? page.title.find(
                        (x) => x.languageCode === languages[0].code
                      ).text
                    : ""
                }
                description={
                  page.excerpt &&
                  page.excerpt.some((x) => x.languageCode === languages[0].code)
                    ? page.excerpt.find(
                        (x) => x.languageCode === languages[0].code
                      ).text
                    : ""
                }
              />
              <Link to={{ pathname: `/page/?pageID=${page.id}` }}>
                <Button>Show/Edit</Button>
              </Link>
            </List.Item>
          )}
        />
      </div>
    </Content>
  );
};
export default PagesDataView;
