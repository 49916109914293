import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { Layout } from "antd";
import "antd/dist/antd.css";
import "ant-design-pro/dist/ant-design-pro.css";
import "./index.css";
import App from "./App";
import SideBar from "./Components/SideBar";
import * as serviceWorker from "./serviceWorker";
import Product from "./Components/Products/Product";
import ProductsDataView from "./Components/Products/ProductsDataView";
import CategoriesDataView from "./Components/Categories/CategoriesDataView";
import Category from "./Components/Categories/Category";
import ManufacturersDataView from "./Components/Manufacturers/ManufacturersDataView";
import Manufacturer from "./Components/Manufacturers/Manufacturer";
import PagesDataView from "./Components/Pages/PagesDataView";
import Page from "./Components/Pages/Page";
import WrappedLoginForm from "./Components/Authentication";
import AppBar from "./Components/AppBar";
import PostsDataView from "./Components/Posts/PostsDataView";
import Post from "./Components/Posts/Post";
import Orders from "./Components/Orders/Orders";
import Banners from "./Components/Banners/Banners";
import Settings from "./Components/Settings/Settings";
import packageJson from "../package.json";
import CustomersDataView from "./Components/Customers/CustomersDataView";
import Customer from "./Components/Customers/Customer";
import Prices from "./Components/Prices/Prices";
import DiscountsDataView from "./Components/Prices/DiscountsDataView";
import Discount from "./Components/Prices/Discount";
import CustomerPricesDataView from "./Components/Prices/CustomerPricesDataView";
import CustomerPrice from "./Components/Prices/CustomerPrice";
import { isValidToken } from "./Components/Utils/Validator";
import Promotion from "./Components/Promotions/Promotion";
import StoragesDataView from "./Components/Storages/StoragesDataView";
import Storage from "./Components/Storages/Storage";
import Inputs from "./Components/Inputs/InputsDataView";
import Remainders from "./Components/Remainders/Remainders";

const { Footer, Content } = Layout;

const routing = (
  <Suspense fallback={<div>Loading...</div>}>
    <Router>
      <div id="routepage">
        <Layout style={{ minHeight: "100vh" }}>
          <SideBar />
          <Layout>
            <AppBar />
            <Route
              render={() => (
                <Layout>
                  <Content>
                    <Switch>
                      <Route path="/login" component={WrappedLoginForm} />
                      <Route
                        exact
                        path="/"
                        render={() => {
                          if (isValidToken()) {
                            return <App />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/products"
                        render={() => {
                          if (isValidToken()) {
                            return <ProductsDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/product"
                        render={() => {
                          if (isValidToken()) {
                            return <Product />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/categories"
                        render={() => {
                          if (isValidToken()) {
                            return <CategoriesDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/category"
                        render={() => {
                          if (isValidToken()) {
                            return <Category />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/manufacturers"
                        render={() => {
                          if (isValidToken()) {
                            return <ManufacturersDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/manufacturer"
                        render={() => {
                          if (isValidToken()) {
                            return <Manufacturer />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/storages"
                        render={() => {
                          if (isValidToken()) {
                            return <StoragesDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/storage"
                        render={() => {
                          if (isValidToken()) {
                            return <Storage />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/pages"
                        render={() => {
                          if (isValidToken()) {
                            return <PagesDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/page"
                        render={() => {
                          if (isValidToken()) {
                            return <Page />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/posts"
                        render={() => {
                          if (isValidToken()) {
                            return <PostsDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/post"
                        render={() => {
                          if (isValidToken()) {
                            return <Post />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/customers"
                        render={() => {
                          if (isValidToken()) {
                            return <CustomersDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/customer"
                        render={() => {
                          if (isValidToken()) {
                            return <Customer />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/prices"
                        render={() => {
                          if (isValidToken()) {
                            return <Prices />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/cprices"
                        render={() => {
                          if (isValidToken()) {
                            return <CustomerPricesDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/cprice"
                        render={() => {
                          if (isValidToken()) {
                            return <CustomerPrice />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/discounts"
                        render={() => {
                          if (isValidToken()) {
                            return <DiscountsDataView />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/discount"
                        render={() => {
                          if (isValidToken()) {
                            return <Discount />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/orders"
                        render={() => {
                          if (isValidToken()) {
                            return <Orders />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/inputs"
                        render={() => {
                          if (isValidToken()) {
                            return <Inputs />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/remainders"
                        render={() => {
                          if (isValidToken()) {
                            return <Remainders />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/banners"
                        render={() => {
                          if (isValidToken()) {
                            return <Banners />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/prmotions"
                        render={() => {
                          if (isValidToken()) {
                            return <Promotion />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                      <Route
                        path="/settings"
                        render={() => {
                          if (isValidToken()) {
                            return <Settings />;
                          } else {
                            return <Redirect to="/login" />;
                          }
                        }}
                      />
                    </Switch>
                  </Content>
                </Layout>
              )}
            />
            <Footer style={{ textAlign: "center" }}>
              © 2019 - {new Date().getFullYear()} {packageJson.name} v
              {packageJson.version} <br /> Created by{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#faa535" }}
                href="https://owltechagency.com/"
              >
                Owltech Agency
              </a>
            </Footer>
          </Layout>
        </Layout>
      </div>
    </Router>
  </Suspense>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
