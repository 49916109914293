import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import UploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";

export const DescriptionEditor = ({
  description,
  setDescription,
  languageCode,
}) => {
  const onDescriptionChange = (event, editor) => {
    const data = editor.getData();
    let tmpDesc = description ? description : [];
    tmpDesc = tmpDesc.filter((x) => x.languageCode !== languageCode);
    tmpDesc.push({ languageCode: languageCode, text: data });

    setDescription(tmpDesc);
  };
  const editorConfiguration = {
    plugins: [
      Essentials,
      UploadAdapter,
      Autoformat,
      Paragraph,
      Bold,
      Italic,
      Heading,
      Link,
      List,
      SimpleUploadAdapter,
      Alignment,
    ],
    toolbar: [
      "heading",
      "|",
      "alignment",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        onChange={onDescriptionChange}
        config={editorConfiguration}
        data={
          description &&
          description.some((x) => x.languageCode === languageCode)
            ? description.find((x) => x.languageCode === languageCode).text
            : ""
        }
      />
    </div>
  );
};

export const CompositionEditor = ({
  description,
  setCompositionDescription,
  languageCode,
}) => {
  const onCompositionDescriptionChange = (event, editor) => {
    const data = editor.getData();
    let tmpDesc = description ? description : [];
    tmpDesc = tmpDesc.filter((x) => x.languageCode !== languageCode);
    tmpDesc.push({ languageCode: languageCode, text: data });

    setCompositionDescription(tmpDesc);
  };
  const editorConfiguration = {
    plugins: [
      Essentials,
      UploadAdapter,
      Autoformat,
      Paragraph,
      Bold,
      Italic,
      Heading,
      Link,
      List,
      SimpleUploadAdapter,
      Alignment,
    ],
    toolbar: [
      "heading",
      "|",
      "alignment",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        onChange={onCompositionDescriptionChange}
        config={editorConfiguration}
        data={
          description &&
          description.some((x) => x.languageCode === languageCode)
            ? description.find((x) => x.languageCode === languageCode).text
            : ""
        }
      />
    </div>
  );
};

export const BoxEditor = ({ description, setBoxDescription, languageCode }) => {
  const onBoxDescriptionChange = (event, editor) => {
    const data = editor.getData();
    let tmpDesc = description ? description : [];
    tmpDesc = tmpDesc.filter((x) => x.languageCode !== languageCode);
    tmpDesc.push({ languageCode: languageCode, text: data });

    setBoxDescription(tmpDesc);
  };
  const editorConfiguration = {
    plugins: [
      Essentials,
      UploadAdapter,
      Autoformat,
      Paragraph,
      Bold,
      Italic,
      Heading,
      Link,
      List,
      SimpleUploadAdapter,
      Alignment,
    ],
    toolbar: [
      "heading",
      "|",
      "alignment",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        onChange={onBoxDescriptionChange}
        config={editorConfiguration}
        data={
          description &&
          description.some((x) => x.languageCode === languageCode)
            ? description.find((x) => x.languageCode === languageCode).text
            : ""
        }
      />
    </div>
  );
};

export const PeriodEditor = ({
  description,
  setPeriodDescription,
  languageCode,
}) => {
  const onPeriodDescriptionChange = (event, editor) => {
    const data = editor.getData();
    let tmpDesc = description ? description : [];
    tmpDesc = tmpDesc.filter((x) => x.languageCode !== languageCode);
    tmpDesc.push({ languageCode: languageCode, text: data });

    setPeriodDescription(tmpDesc);
  };
  const editorConfiguration = {
    plugins: [
      Essentials,
      UploadAdapter,
      Autoformat,
      Paragraph,
      Bold,
      Italic,
      Heading,
      Link,
      List,
      SimpleUploadAdapter,
      Alignment,
    ],
    toolbar: [
      "heading",
      "|",
      "alignment",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        onChange={onPeriodDescriptionChange}
        config={editorConfiguration}
        data={
          description &&
          description.some((x) => x.languageCode === languageCode)
            ? description.find((x) => x.languageCode === languageCode).text
            : ""
        }
      />
    </div>
  );
};

export const MailDescriptionEditor = ({
  description,
  setSettings,
  languageCode,
}) => {
  const onDescriptionChange = (event, editor) => {
    const data = editor.getData();
    let tmpDesc = description ? description : [];
    tmpDesc = tmpDesc.filter((x) => x.languageCode !== languageCode);
    tmpDesc.push({ languageCode: languageCode, text: data });

    setSettings((settings) => ({
      ...settings,
      mailSettings: { ...settings.mailSettings, description: tmpDesc },
    }));
  };
  const editorConfiguration = {
    plugins: [
      Essentials,
      UploadAdapter,
      Autoformat,
      Paragraph,
      Bold,
      Italic,
      Heading,
      Link,
      List,
      SimpleUploadAdapter,
      Alignment,
    ],
    toolbar: [
      "heading",
      "|",
      "alignment",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        onChange={onDescriptionChange}
        config={editorConfiguration}
        data={
          description &&
          description.some((x) => x.languageCode === languageCode)
            ? description.find((x) => x.languageCode === languageCode).text
            : ""
        }
      />
    </div>
  );
};
