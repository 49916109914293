import api from "../../Api";
import Cookie from "js-cookie";

export const ProductNameExists = async (productName, excludeId) => {
/*   let productNameExist = 0;
  await api
    .products()
    .get()
    .then((response) => {
      if (excludeId === null) {
        productNameExist = response.data.filter(
          (x) =>
            x.name.trim().toUpperCase() === productName.trim().toUpperCase()
        ).length;
      } else {
        productNameExist = response.data.filter(
          (x) =>
            x.id !== excludeId &&
            x.name.trim().toUpperCase() === productName.trim().toUpperCase()
        ).length;
      }
    });
  return productNameExist; */
};

export const AreaExists = async (area, excludeId) => {
  let areaExists = 0;
  await api
    .geoZones()
    .get()
    .then((response) => {
      if (excludeId === null) {
        areaExists = response.data.filter(
          (x) =>
            x.area.trim().toUpperCase() === area.trim().toUpperCase()
        ).length;
      } else {
        areaExists = response.data.filter(
          (x) =>
            x.id !== excludeId &&
            x.area.trim().toUpperCase() === area.trim().toUpperCase()
        ).length;
      }
    });
  return areaExists;
};

export const ValidateToken = async (history) => {
  if (
    !Cookie.get("jwtToken") ||
    Cookie.get("jwtToken") === "" ||
    Cookie.get("expTime") < Date.now() / 1000
  ) {
      history.push(`/login`);
  }
}

export const isValidToken = () => {
  if (
    !Cookie.get("jwtToken") ||
    Cookie.get("jwtToken") === "" ||
    Cookie.get("expTime") < Date.now() / 1000
  ) {
      return false;
  }
  return true;
}