import React, { useState, useEffect } from "react";
import api from "./../../Api";
import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Button, Col, message, Upload, Modal, Input } from "antd";

const API_URL = process.env.REACT_APP_API_URL;

//show image
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const BaseBanner = ({ displayName, name, banners, style,size }) => {
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const [additionalLink, setAdditionalLink] = useState("");

  useEffect(() => {
    const ImagesUrls = async () => {
      //get product images
      const imagesUrls = [];
      const img = banners.filter((img) => img.bannerName === name);
      if (img.length > 0) {
        imagesUrls.push({
          uid: img[0].id,
          name: name,
          status: "done",
          url: `${API_URL}/${img[0].url}`,
          additionalLink: img[0].additionalLink,
        });
      }
      setImages((images) => ({ ...images, fileList: imagesUrls }));
      setOldImages(imagesUrls);
      setAdditionalLink(
        imagesUrls.length > 0 ? imagesUrls[0].additionalLink : ""
      );
    };
    ImagesUrls();
  }, [banners, name]);

  const handleSubmit = async () => {

    let formData = new FormData();
    if (!!images.fileList) {
      for (let i = 0; i < images.fileList.length; i++) {
        formData.append("image", images.fileList[i].originFileObj);
      }

      await api
        .uploads()
        .post(formData)
        .then(async (res) => {
          res.data.forEach((res) => {
            api.images().post({
              bannerName: name,
              url: res.path,
            });
          });
        });
    }

    // get images differences
    let intersection = oldImages.filter((x) => !images.fileList.includes(x));
    for (let i = 0; i < intersection.length; i++) {
      let uid = intersection[i].uid;
      await api.images(uid).delete(formData);
    }

    //get product images

    const result = await api.images().get();
    const img = result.data.filter((x) => x.bannerName === name);
    if (img.length > 0) {
      api.images().post({
        id: result.data.filter((x) => x.bannerName === name)[0].id,
        additionalLink: additionalLink,
      });
    }
    await message.success("Banners successfully updated", 0.5);
    window.location.reload();
  };

  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
    }));
  };

  const onAdditionalLinkChange = (e) => {
    setAdditionalLink(e.target.value);
  };

  const handleImageChange = ({ fileList }) => {
    if (fileList.length > 0) {
      fileList[0].bannerName = name;
    }
    setImages((images) => ({ ...images, fileList }));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div style={style}>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Row type="flex" justify="center" align="top">
          <Col span={12}>
            <h3>
              {displayName} <br />
              {size}
            </h3>
          </Col>
          <Col span={12}>
            <Form.Item>
              <div className="clearfix">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  accept=".jpg, .jpeg, .png, .gif"
                  listType="picture-card"
                  fileList={images.fileList}
                  onPreview={handlePreview}
                  onChange={handleImageChange}
                  name={name}
                >
                  {!!images.fileList && images.fileList.length >= 1
                    ? null
                    : uploadButton}
                </Upload>
                <Modal
                  visible={images.previewVisible}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={images.previewImage}
                  />
                </Modal>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <label>
          Additional link
          <Input
            allowClear
            onChange={onAdditionalLinkChange}
            placeholder="Enter additional link"
            value={additionalLink}
          />
        </label>
        <Row type="flex" justify="center" gutter={32}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
export default BaseBanner;