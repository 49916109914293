import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CloudSyncOutlined, PlusOutlined } from "@ant-design/icons";
import { List, Layout, Button, Row, Col, Input, message } from "antd";
import api from "../../Api";

const { Content } = Layout;
const { Search } = Input;

const CustomersDataView = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [axonautButtonLoading, setAxonautButtonLoading] = useState(false);
  const [information, setInformation] = useState({});

  useEffect(() => {
    const getCustomers = async () => {
      const res = await api.getCustomers().get();
      setCustomers(
        res.data.sort(
          (a, b) =>
            new Date(a.requestDate).getTime() -
            new Date(b.requestDate).getTime()
        )
      );
      setLoading(false);
    };
    const getInformation = async () => {
      await api
        .informations(1)
        .get()
        .then((response) => setInformation(response.data))
        .catch((ex) => console.log(ex.data));
    };
    getInformation();
    getCustomers();
  }, []);

  const handleOnSearch = (value) => {
    api
      .getCustomers()
      .get()
      .then((res) => {
        if (value) {
          setCustomers(
            res.data
              .sort(
                (a, b) =>
                  new Date(a.requestDate).getTime() -
                  new Date(b.requestDate).getTime()
              )
              .filter((customer) =>
                customer.businessName
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
          );
        } else {
          setCustomers(
            res.data.sort(
              (a, b) =>
                new Date(a.requestDate).getTime() -
                new Date(b.requestDate).getTime()
            )
          );
        }
      });
  };

  const lineStyles = (customer) => {
    if (customer.state !== "verified") {
      return {
        background:
          "linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255 231 231) 50%, rgb(255, 255, 255) 100%)",
      };
    }
  };

  const axonautButtonOnClick = async () => {
    setAxonautButtonLoading(true);
    await api
      .addAxonautCustomers()
      .get()
      .then((res) => {
        message.success("Customer list has been updated", 5);
        if (res.data === "Synced") {
          setAxonautButtonLoading(false);
        }
      })
      .catch((ex) => console.log(ex.data));
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={6}>
          <h1>Customers</h1>
        </Col>
        <Col span={12}>
          <Search
            type="text"
            name="search"
            enterButton="Search"
            placeholder="input search text"
            onSearch={handleOnSearch}
          />
        </Col>
        <Col span={6}>
          <Row type="flex" justify="end" align="middle">
            <div className="addNew">
              {information.axonautToken && information.axonautToken !== "" && (
                <Button
                  type="primary"
                  loading={axonautButtonLoading}
                  icon={<CloudSyncOutlined />}
                  onClick={axonautButtonOnClick}
                  style={{ marginRight: 12 }}
                >
                  Add to Axonaut
                </Button>
              )}
            </div>
            <div className="addNew">
              <Link to={{ pathname: `/customer/?isNew=true` }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="green-btn"
                >
                  Add new
                </Button>
              </Link>
            </div>
          </Row>
        </Col>
      </Row>
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "left",
          borderRadius: 12,
        }}
      >
        <List
          itemLayout="horizontal"
          loading={loading}
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
          }}
          dataSource={customers}
          renderItem={(customer) => (
            <List.Item key={customer.id} style={lineStyles(customer)}>
              <p style={{ marginBottom: 0, marginRight: 6, fontWeight: 700 }}>
                {customers.findIndex((x) => x.id === customer.id) + 1}.
              </p>
              <List.Item.Meta
                title={customer.businessName}
                description={
                  customer.TIN +
                  " " +
                  (customer.externalId && customer.externalId !== 0
                    ? "| Axounaut id -" + customer.externalId
                    : "")
                }
              />
              <p style={{ marginBottom: 0, marginRight: 6, fontWeight: 500 }}>
                {new Date(customer.requestDate).getFullYear() +
                  "-" +
                  (new Date(customer.requestDate).getMonth() + 1) +
                  "-" +
                  new Date(customer.requestDate).getDate()}
              </p>
              <Link to={{ pathname: `/customer/?customerID=${customer.id}` }}>
                <Button>Show/Edit</Button>
              </Link>
            </List.Item>
          )}
        />
      </div>
    </Content>
  );
};

export default CustomersDataView;
