import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Layout, Button, Row, Col, Collapse } from "antd";
import api from "./../../Api";
import { CategoryName } from "../Utils/Common";

const { Content } = Layout;
const { Panel } = Collapse;

const CategoriesDataView = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [nextId, setNextId] = useState();

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.categories("All").get();
      setNextId(
        Math.max.apply(
          Math,
          data.map(function (o) {
            return o.id;
          })
        ) + 1
      );

      setSubCategories(data.filter((x) => x.parentId !== 0));
      setCategories(data);
    };
    getCategories();
  }, []);

  const callback = (key) => {
    console.log(key);
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <h1>Categories</h1>
        </Col>
        <Col span={12}>
          <div className="addNew">
            <Link
              to={{ pathname: `/category/?categoryID=${nextId}&isNew=true` }}
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="green-btn"
              >
                Add new
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "left",
          borderRadius: 12,
        }}
      >
        {categories.map((parent) =>
          parent.parentId === 0 ? (
            <Collapse
              key={parent.id}
              onChange={callback}
              style={{ marginBottom: 12 }}
            >
              <Panel
                header={parent.code + " " + CategoryName(parent)}
                key={parent.code}
                disabled={
                  categories.filter((x) => x.parentId === parent.id).length ===
                  0
                }
                extra={
                  <Link
                    to={{
                      pathname: `/category/?categoryID=${parent.id}`,
                    }}
                  >
                    <Button>Show/Edit</Button>
                  </Link>
                }
              >
                <ul>
                  {subCategories
                    .filter((x) => x.parentId === parent.id)
                    .map((child) => (
                      <div key={child.id}>
                        <li style={{ listStyle: "none" }}>
                          <Row
                            style={{ borderBottom: "1px solid #d9d9d9" }}
                            type="flex"
                            justify="space-between"
                            align="middle"
                          >
                            <h2>
                              {child.code} - {CategoryName(child)}
                            </h2>
                            <Link
                              to={{
                                pathname: `/category/?categoryID=${child.id}`,
                              }}
                            >
                              <Button>Show/Edit</Button>
                            </Link>
                          </Row>
                        </li>
                        <div>
                          {subCategories
                            .filter((x) => x.parentId === child.id)
                            .map((subChild) => (
                              <li
                                key={subChild.id}
                                style={{ listStyle: "none" }}
                              >
                                <Row
                                  style={{ borderBottom: "1px solid #d9d9d9" }}
                                  type="flex"
                                  justify="space-between"
                                  align="middle"
                                >
                                  <h2>
                                    {child.code} - {CategoryName(child)} {"->"}
                                    {subChild.code} - {CategoryName(subChild)}
                                  </h2>
                                  <Link
                                    to={{
                                      pathname: `/category/?categoryID=${subChild.id}`,
                                    }}
                                  >
                                    <Button>Show/Edit</Button>
                                  </Link>
                                </Row>
                              </li>
                            ))}
                        </div>
                      </div>
                    ))}
                </ul>
              </Panel>
            </Collapse>
          ) : (
            ""
          )
        )}
      </div>
    </Content>
  );
};
export default CategoriesDataView;
