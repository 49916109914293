import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CopyOutlined,
  DragOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Button,
  Row,
  Col,
  Input,
  Tabs,
  message,
  Select,
  Checkbox,
  Table,
  Tag,
  Divider,
  Modal,
  Form,
  Switch,
  Progress,
} from "antd";
import ListSort from "../Utils/ListSort";
import api from "../../Api";
import languages from "../Languages/languages.json";

const { Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const ProductsDataView = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ordering, setOrdering] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [modal, setModal] = useState({
    ModalText: "Edit selected",
    visible: false,
    confirmLoading: false,
  });
  const [editSelectedFields, setEditSelectedFields] = useState({
    isActive: false,
    taxRate: false,
    favorite: false
  });
  const [bulkPercent, setBulkPercent] = useState(0);

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      await api
        .products()
        .get()
        .then((res) => {
          if (selectedFilters) {
            let tmpProducts = res.data;
            if (
              selectedFilters.category &&
              selectedFilters.category !== undefined
            ) {
              tmpProducts = tmpProducts.filter(
                (x) => x.categoryId === selectedFilters.category
              );
            }
            if (selectedFilters.isActive === true) {
              tmpProducts = tmpProducts.filter((x) => x.isActive);
            }
            if (selectedFilters.search) {
              tmpProducts = tmpProducts.filter(
                (product) =>
                  product.name &&
                  product.name
                    .find((x) => x.languageCode === languages[0].code)
                    .text.toUpperCase()
                    .includes(selectedFilters.search.toUpperCase())
              );
            }
            setProducts(tmpProducts);
          }
          setLoading(false);
        });
    };
    const getCategories = async () => {
      const { data } = await api.categories("isActive").get();
      setCategories(data);
    };
    getProducts();
    getCategories();
  }, [selectedFilters]);

  const handleOnSearch = (value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      search: value,
    }));
  };

  const handleSaveOrdering = async () => {
    ordering.forEach((item, index) => {
      api.products().post({ id: item.props.product, ordering: index });
    });
    await message.success("Ordering successfully edited", 1);
    window.location.reload();
  };

  const handleCategoryChange = async (value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      category: value,
    }));
  };

  const handleisActiveChange = async (e) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      isActive: e.target.checked,
    }));
  };

  const sortableProducts = products
    .filter((x) => x.favorite)
    .sort((a, b) => a.ordering - b.ordering)
    .map((item, i) => {
      return (
        <div key={i} product={item.id} className="list-sort-demo-list">
          <div className="list-sort-demo-icon">
            <DragOutlined />
          </div>
          <div className="list-sort-demo-text">
            <h1>{item.code}</h1>
            <p>{item.name ? item.name[0].text : ""}</p>
          </div>
        </div>
      );
    });
  const handleSortChange = (e) => {
    setOrdering(e);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (productName) => (
        <>
          {productName &&
          productName.some((x) => x.languageCode === languages[0].code)
            ? productName.find((x) => x.languageCode === languages[0].code).text
            : ""}
        </>
      ),
    },
    {
      title: "Tags",
      dataIndex: "favorite",
      render: (favorite) =>
        favorite && (
          <span>
            <Tag color="geekblue">FAVORITE</Tag>
          </span>
        ),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      render: (isActive) =>
        isActive && (
          <span>
            <Tag color="green">IS ACTIVE</Tag>
          </span>
        ),
    },
    {
      title: "Show/Edit/Duplicate",
      dataIndex: "id",
      render: (id) => (
        <>
          <Link to={{ pathname: `/product/?copyProductID=${id}&isNew=true` }}>
            <CopyOutlined className="copy-icon" />
          </Link>
          <Link to={{ pathname: `/product/?productID=${id}` }}>
            <Button>Show/Edit</Button>
          </Link>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
    window.location.reload();
  };

  const onFinish = (values) => {
    setLoading(true);

    let data = {};
    if (values.isActiveSwitch) {
      data = {
        ...data,
        isActive: values.isActive ? true : false,
      };
    }
    if (values.taxRateSwitch) {
      data = {
        ...data,
        taxRate: Number(values.taxRate),
      };
    }
    if (values.favoriteSwitch) {
      data = {
        ...data,
        favorite: values.favorite ? true : false,
      };
    }
    let current = 0;

    selectedRows.forEach(async (id, index) => {
      setTimeout(() => {
        data = {
          ...data,
          id: id,
        };
        api
          .products()
          .post(data)
          .then((res) => {
            if (res.data.error) {
              console.log(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        current++;
        setBulkPercent((current / selectedRows.length) * 30 + 70);
      }, index * 1000);
    });
    setLoading(false);
  };

  const isActiveChangeSwitch = (value) => {
    setEditSelectedFields({
      ...editSelectedFields,
      isActive: value,
    });
  };

  const taxRateChangeSwitch = (value) => {
    setEditSelectedFields({
      ...editSelectedFields,
      taxRate: value,
    });
  };

  const favoriteChangeSwitch = (value) => {
    setEditSelectedFields({
      ...editSelectedFields,
      favorite: value,
    });
  };

  return (
    <Tabs className="dataview-nav" defaultActiveKey="1">
      <TabPane tab="All Products" key="1">
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={6}>
              <h1>All Products</h1>
            </Col>
            <Col span={6}>
              <Select
                showSearch
                allowClear
                style={{ width: 200 }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleCategoryChange}
                placeholder="Please select category"
              >
                {categories.map((cat) => (
                  <Option value={cat.id} key={cat.id}>
                    {cat.name &&
                    cat.name.some((x) => x.languageCode === languages[0].code)
                      ? cat.name.find(
                          (x) => x.languageCode === languages[0].code
                        ).text
                      : ""}
                  </Option>
                ))}
              </Select>
              <Checkbox
                onChange={handleisActiveChange}
                style={{ marginLeft: 12 }}
              >
                Is Active
              </Checkbox>
            </Col>
            <Col span={6}>
              <Search
                type="text"
                name="search"
                enterButton="Search"
                placeholder="input product name"
                onSearch={handleOnSearch}
              />
            </Col>
            <Col span={6}>
              <div className="addNew">
                <Link
                  to={{ pathname: `/product/?isNew=true` }}
                >
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    className="green-btn"
                  >
                    Add new
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <div
            className="products-list"
            style={{
              padding: 24,
              background: "#fff",
              textAlign: "left",
              borderRadius: 12,
            }}
          >
            <Button
              type="primary"
              onClick={showModal}
              disabled={selectedRows.length === 0}
            >
              Edit Selected
            </Button>
            <Modal
              visible={modal.visible}
              confirmLoading={modal.confirmLoading}
              onCancel={handleModalCancel}
              footer={null}
            >
              <h2>{modal.ModalText}</h2>
              <Form name="edit-selected" onFinish={onFinish}>
                <Form.Item label="Is active">
                  <Form.Item
                    label=""
                    name="isActiveSwitch"
                    style={{
                      display: "inline-block",
                      width: "calc(10% - 8px)",
                    }}
                  >
                    <Switch size="small" onChange={isActiveChangeSwitch} />
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="isActive"
                    valuePropName="checked"
                    style={{
                      display: "inline-block",
                      width: "calc(90% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Checkbox disabled={!editSelectedFields.isActive} />
                  </Form.Item>
                </Form.Item>
                <Form.Item label="Is favorite">
                  <Form.Item
                    label=""
                    name="favoriteSwitch"
                    style={{
                      display: "inline-block",
                      width: "calc(10% - 8px)",
                    }}
                  >
                    <Switch size="small" onChange={favoriteChangeSwitch} />
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="favorite"
                    valuePropName="checked"
                    style={{
                      display: "inline-block",
                      width: "calc(90% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Checkbox disabled={!editSelectedFields.favorite} />
                  </Form.Item>
                </Form.Item>
                <Form.Item label="Tax rate">
                  <Form.Item
                    label=""
                    name="taxRateSwitch"
                    style={{
                      display: "inline-block",
                      width: "calc(10% - 8px)",
                    }}
                  >
                    <Switch
                      size="small"
                      style={{ marginRight: 6 }}
                      onChange={taxRateChangeSwitch}
                    />
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="taxRate"
                    style={{
                      display: "inline-block",
                      width: "calc(90% - 8px)",
                      margin: "0 8px",
                    }}
                    rules={[
                      {
                        required: editSelectedFields.taxRate,
                        message: 'Please select tax rate!',
                      },
                    ]}
                  >
                    <Select
                      disabled={!editSelectedFields.taxRate}
                      placeholder="Please select tax rate"
                      style={{ width: "80%" }}
                    >
                      <Option value={20}>Standard rate (20.00%)</Option>
                      <Option value={10}>Intermediate rate (10.00%)</Option>
                      <Option value={5.5}>Reduced rate (5.50%)</Option>
                      <Option value={0}>No tax (0.00%)</Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Divider />
                <Progress
                  percent={bulkPercent}
                  status={bulkPercent === 100 ? "" : "active"}
                />
                <Form.Item>
                  {bulkPercent === 0 ? 
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                  >
                    Update
                  </Button>
                  :
                  <Button
                  disabled={bulkPercent > 0 && bulkPercent < 100}
                  type="default"
                  onClick={handleModalCancel}
                >
                  Close
                </Button>
                }
                </Form.Item>
              </Form>
            </Modal>
            <Divider />
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              rowKey="id"
              columns={columns}
              loading={loading}
              dataSource={products}
              tableLayout="fixed"
              pagination={{
                onChange: (page) => {
                  localStorage.setItem("currentPage", page);
                },
                defaultCurrent: JSON.parse(localStorage.getItem("currentPage")) ?? 1,
              }}
            />
          </div>
        </Content>
      </TabPane>
      <TabPane tab="Favorites" key="2">
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={6}>
              <h1>Favorites</h1>
            </Col>
          </Row>
          <div
            style={{
              padding: 24,
              background: "#fff",
              textAlign: "left",
              borderRadius: 12,
            }}
          >
            <div className="list-sort-demo-wrapper ">
              <div className="list-sort-demo">
                <Row type="flex" justify="end" align="middle">
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    onClick={handleSaveOrdering}
                  >
                    Save
                  </Button>
                </Row>
                <ListSort
                  dragClassName="list-drag-selected"
                  appearAnim={{
                    animConfig: { marginTop: [5, 30], opacity: [1, 0] },
                  }}
                  onChange={handleSortChange}
                >
                  {sortableProducts}
                </ListSort>
              </div>
            </div>
          </div>
        </Content>
      </TabPane>
    </Tabs>
  );
};
export default ProductsDataView;
