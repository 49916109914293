import React, { useState, useEffect } from "react";
import {
  Row,
  Divider,
  Layout,
  Spin,
  Col,
  DatePicker,
  Select,
  message,
  List,
  Button,
  Drawer,
  Modal,
} from "antd";
import api from "../../Api";
import OrderDetails from "./OrderDetails";
import {
  CloudServerOutlined,
  CloudUploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import NewOrder from "./NewOrder";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [spinning, setSpinning] = useState(true);
  const [orderDrawer, setOrderDrawer] = useState((orderDrawer) => ({
    ...orderDrawer,
    visible: false,
  }));
  const [customers, setCustomers] = useState([]);
  const [information, setInformation] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [newOrder, setNewOrder] = useState({});
  const [axonautButtonLoading, setAxonautButtonLoading] = useState({
    docId: null,
    isLoading: false,
  });
  const [modal, setModal] = useState({
    ModalText: "Confirm order delete",
    visible: false,
    confirmLoading: false,
  });
  const [newOrderModal, setNewOrderModal] = useState({
    ModalText: "Add new order",
    visible: false,
    confirmLoading: false,
  });

  const handleCustomerSelect = async (value) => {
    setSelectedCustomer(value);
  };

  const onDateRangeChange = async (date, dateString) => {
    setSelectedDate(dateString);
  };

  const handlePaymentStatus = async (value, orderUid) => {
    await api
      .documents()
      .post({
        id: orderUid,
        paymentStatus: value,
      })
      .then(message.success("Order's payment status updated", 1));
  };

  const handleFulfillmentStatus = async (value, orderUid) => {
    await api
      .documents()
      .post({
        id: orderUid,
        fulfillmentStatus: value,
      })
      .then(message.success("Order's fulfillment status updated", 1));
  };

  useEffect(() => {
    const getOrders = async () => {
      await api
        .documents()
        .get({
          params: { type: 1 },
        })
        .then((response) => {
          let tempOrders = [];
          if (selectedCustomer === undefined) {
            tempOrders = response.data;
          } else {
            tempOrders = response.data.filter(
              (x) => x.customerUid === selectedCustomer
            );
          }
          if (selectedDate && selectedDate[0] !== "") {
            let dateOne = Date.parse(selectedDate[0]);
            let dateTwo =
              selectedDate[0] !== selectedDate[1]
                ? Date.parse(selectedDate[1])
                : Date.parse(selectedDate[1]) + 86400000;
            tempOrders = tempOrders.filter(
              (x) =>
                Date.parse(x.date) > dateOne && Date.parse(x.date) < dateTwo
            );
          }
          setOrders(tempOrders);
          setSpinning(false);
        });
    };

    const getCustomers = async () => {
      await api
        .getCustomers()
        .get()
        .then((response) => setCustomers(response.data));
    };
    const getInformation = async () => {
      await api
        .informations(1)
        .get()
        .then((response) => setInformation(response.data))
        .catch((ex) => console.log(ex.data));
    };

    getOrders();
    getCustomers();
    getInformation();
  }, [selectedCustomer, selectedDate]);

  const showOrderDrawer = (orderUid) => {
    setOrderDrawer({
      orderUid: orderUid,
      visible: true,
    });
  };

  const closeOrderDrawer = (orderUid) => {
    setOrderDrawer({
      orderUid: orderUid,
      visible: false,
    });
  };

  //order delete modal
  const showDeleteModal = (orderUid) => {
    setModal((modal) => ({
      ...modal,
      visible: true,
      orderUid: orderUid,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api
      .documents()
      .delete({
        params: { Uid: modal.orderUid, type: 1 },
      })
      .then((res) => console.log(res.data));
    await message.warning(`Order N ${modal.orderUid} successfully deleted`, 1);
    window.location.reload();
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  //New order modal
  const showNewOrderModal = () => {
    setNewOrderModal((newOrderModal) => ({
      ...newOrderModal,
      visible: true,
    }));
  };

  const handleNewOrderModalOk = async () => {
    let err = false;
    newOrder.orderDetails.forEach((row, index) => {
      if (
        newOrder.orderDetails.some(
          (x) =>
            x.productId === row.productId &&
            row.orderUid === "" &&
            x.orderUid !== ""
        )
      ) {
        message.error(
          `There is a product with the same name ${index + 1} , ${
            newOrder.orderDetails.findIndex(
              (x) =>
                x.productId === row.productId &&
                row.orderUid === "" &&
                x.orderUid !== ""
            ) + 1
          }`,
          1
        );
        err = true;
      }
    });
    if (newOrder.orderDetails.length === 0) {
      message.error(`Products list is empty`, 1);
      err = true;
    }
    if (newOrder.orderDetails.some((x) => x.storageId === 0)) {
      message.error(`Warehouse is required`, 1);
      err = true;
    }
    if (err) {
      return;
    }

    setNewOrderModal((newOrderModal) => ({
      ...newOrderModal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setNewOrderModal((newOrderModal) => ({
        ...newOrderModal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);

    //store new order
    let total = newOrder.orderDetails.reduce(
      (a, b) => a + b.price * b.quantity,
      0
    );
    const documentData = {
      customerUid: newOrder.customerUid,
      notes: newOrder.notes,
      type: 1,
      paymentStatus: "pending",
      fulfillmentStatus: "pending",
      totalSum: Number(total.toFixed(2)),
    };
    await api
      .documents()
      .post(documentData)
      .then(async (response) => {
        const ordersBody = [];
        newOrder.orderDetails.forEach((row) => {
          ordersBody.push({
            orderUid: response.data.id,
            productId: row.productId,
            quantity: row.quantity,
            price: row.price,
            storageId: row.storageId,
          });
        });
        await api
          .orders()
          .post(ordersBody)
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
    await message.success(`New order added`, 1);
    window.location.reload();
  };

  const handleNewOrderModalCancel = () => {
    setNewOrderModal((newOrderModal) => ({
      ...newOrderModal,
      visible: false,
    }));
  };

  const axonautButtonOnClick = async (doc) => {
    setAxonautButtonLoading({ docId: doc.id, isLoading: true });
    await api
      .sendInvoice()
      .post(doc)
      .then(async (res) => {
        if (res.data && res.data.message) {
          await message.error(res.data.message, 2);
          setAxonautButtonLoading({ docId: doc.id, isLoading: false });
          window.location.reload();
        } else {
          await message.success("Document uploaded", 1);
          setAxonautButtonLoading({ docId: doc.id, isLoading: false });
          window.location.reload();
        }
      })
      .catch((ex) => console.log(ex));
  };

  return (
    <div className="orders">
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>Orders</h1>
            <Row type="flex" justify="space-between" align="middle" gutter={32}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select customer"
                  onChange={handleCustomerSelect}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {customers.map((customer) => (
                    <Option value={customer.id} key={customer.id}>
                      {customer.businessName}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <RangePicker onChange={onDateRangeChange} />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 6 }}
                style={{ textAlign: "end" }}
              >
                <Button
                  type="primary"
                  className="green-btn"
                  onClick={showNewOrderModal}
                  icon={<PlusOutlined />}
                >
                  Add new
                </Button>
                <Modal
                  title={`Add new order`}
                  width="90%"
                  visible={newOrderModal.visible}
                  maskClosable={false}
                  onOk={handleNewOrderModalOk}
                  onCancel={handleNewOrderModalCancel}
                  footer={[
                    <Button key="back" onClick={handleNewOrderModalCancel}>
                      Cancel
                    </Button>,
                    <Button
                      className="green-btn"
                      key="submit"
                      type="primary"
                      loading={newOrderModal.confirmLoading}
                      onClick={handleNewOrderModalOk}
                      disabled={
                        newOrder.orderDetails === undefined ||
                        (newOrder.orderDetails &&
                          newOrder.orderDetails.length === 0) ||
                        (newOrder.orderDetails &&
                          newOrder.orderDetails.some((x) => x.productId === 0))
                      }
                    >
                      Add new order
                    </Button>,
                  ]}
                >
                  <NewOrder setNewOrder={setNewOrder} customers={customers} />
                </Modal>
              </Col>
            </Row>
            <Divider />
            <Row type="flex" justify="center" align="middle">
              <List
                style={{ width: "100%" }}
                itemLayout="horizontal"
                loading={spinning}
                bordered
                pagination={{
                  onChange: (page) => {
                    localStorage.setItem("orderCurrentPage", page);
                  },
                  defaultCurrent:
                    JSON.parse(localStorage.getItem("orderCurrentPage")) ?? 1,
                }}
                dataSource={orders.sort(
                  (a, b) => new Date(b.date) - new Date(a.date)
                )}
                renderItem={(order) => (
                  <List.Item key={order.id}>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={32}
                      style={{ width: "100%" }}
                    >
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <List.Item.Meta
                          title={
                            customers &&
                            customers.some((x) => x.id === order.customerUid)
                              ? customers.find(
                                  (x) => x.id === order.customerUid
                                ).businessName
                              : ""
                          }
                          description={
                            order.totalSum +
                            " € - Order N: " +
                            order.id +
                            (order.invoiceNumber
                              ? " - Axonaut Invoice N: " + order.invoiceNumber
                              : "")
                          }
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 3 }} lg={{ span: 3 }}>
                        <Row type="flex" justify="space-between" align="middle">
                          {information.axonautToken &&
                            information.axonautToken !== "" && (
                              <div>
                                {!order.invoiceNumber ? (
                                  <Button
                                    size="small"
                                    type="primary"
                                    loading={
                                      axonautButtonLoading.docId === order.id &&
                                      axonautButtonLoading.isLoading
                                    }
                                    icon={<CloudUploadOutlined />}
                                    onClick={() => axonautButtonOnClick(order)}
                                  >
                                    Send to Axonaut
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    type="primary"
                                    className="green-btn"
                                    icon={<CloudServerOutlined />}
                                  >
                                    Sent
                                  </Button>
                                )}
                              </div>
                            )}
                          <div className="order-date">
                            {new Date(order.date).getFullYear() +
                              "-" +
                              (new Date(order.date).getMonth() + 1) +
                              "-" +
                              new Date(order.date).getDate()}
                          </div>
                        </Row>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 3 }} lg={{ span: 3 }}>
                        <div className="order-status">Payment status:</div>
                        <Select
                          defaultValue={order.paymentStatus}
                          style={{ width: 120 }}
                          onChange={(e) => handlePaymentStatus(e, order.id)}
                        >
                          <Option value="pending">Pending</Option>
                          <Option value="payed">Payed</Option>
                        </Select>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 3 }} lg={{ span: 3 }}>
                        <div className="order-status" style={{ marginLeft: 4 }}>
                          Fulfillment status:
                        </div>
                        <Select
                          defaultValue={order.fulfillmentStatus}
                          style={{ width: 120, marginRight: 12 }}
                          onChange={(e) => handleFulfillmentStatus(e, order.id)}
                        >
                          <Option value="pending">Pending</Option>
                          <Option value="shipped">Shipped</Option>
                        </Select>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 3 }}
                        lg={{ span: 3 }}
                        style={{ textAlign: "right" }}
                      >
                        <Button onClick={() => showOrderDrawer(order.id)}>
                          Show
                        </Button>
                        <Button
                          type="primary"
                          style={{ marginLeft: 4 }}
                          danger
                          onClick={() => showDeleteModal(order.id)}
                        >
                          Delete
                        </Button>
                      </Col>
                      <Modal
                        title={`Are you sure delete order N ${modal.orderUid}?`}
                        visible={modal.visible}
                        onOk={handleModalOk}
                        confirmLoading={modal.confirmLoading}
                        onCancel={handleModalCancel}
                      >
                        <p>{modal.ModalText}</p>
                      </Modal>
                      <Drawer
                        title="View Order"
                        width={window.innerWidth < 576 ? "100%" : "75%"}
                        closable={true}
                        onClose={() => closeOrderDrawer(order.id)}
                        visible={
                          orderDrawer.visible &&
                          orderDrawer.orderUid === order.id
                        }
                      >
                        <OrderDetails order={order} customers={customers} />
                      </Drawer>
                    </Row>
                  </List.Item>
                )}
              />
            </Row>
            <Divider />
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Orders;
