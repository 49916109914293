import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Row,
  Input,
  Button,
  Layout,
  Col,
  message,
  Spin,
  Upload,
  Modal,
  Tabs,
  InputNumber,
  Divider,
  Checkbox,
  Form,
} from "antd";
import languages from "../Languages/languages.json";
import api from "../../Api";
import MailSettings from "./MailSettings";
//import WrappedGeoZoneForm from "./GeoZones";

const API_URL = process.env.REACT_APP_API_URL;
const { Content } = Layout;
const { TabPane } = Tabs;
const { TextArea } = Input;

//show image
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const Settings = () => {
  const [settings, setSettings] = useState({
    companyName: "",
    description: "",
    address1: "",
    address2: "",
    mail: "",
    geoLocation: "",
    phone1: "",
    phone2: "",
    logoUrl: "",
    axonautToken: "",
  });
  const [spinning, setSpinning] = useState(true);
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      const imagesUrls = [];
      const { data } = await api.informations(1).get();
      if (data) {
        if (data.logoUrl !== "") {
          imagesUrls.push({
            uid: data ? data.id : -1,
            name: "logo",
            status: "done",
            url: `${API_URL}/${data ? data.logoUrl : ""}`,
          });
          setImages((images) => ({ ...images, fileList: imagesUrls }));
          setOldImages(imagesUrls);
        }
        setSettings(data);
      }
      setSpinning(false);
    };
    fetchSettings();
  }, []);

  const handleTranslateFieldsChange = (event, languageCode) => {
    event.persist();
    let data = event.target.value;

    let tmpArray = settings[event.target.name]
      ? settings[event.target.name]
      : [];
    tmpArray = tmpArray.filter((x) => x.languageCode !== languageCode);
    tmpArray.push({ languageCode: languageCode, text: data });
    setSettings((settings) => ({
      ...settings,
      [event.target.name]: tmpArray,
    }));
  };

  const onExchangeRateChange = (value) => {
    setSettings((settings) => ({
      ...settings,
      exchangeRate: value,
    }));
  };

  const onTaxRateChange = (value) => {
    setSettings((settings) => ({
      ...settings,
      taxRate: value,
    }));
  };

  const onAxonautTokenChange = (e) => {
    e.persist();
    setSettings((settings) => ({
      ...settings,
      axonautToken: e.target.value,
    }));
  };
  console.log(settings);
  const handleSubmit = async () => {
    let formData = new FormData();
    if (!!images.fileList) {
      for (let i = 0; i < images.fileList.length; i++) {
        formData.append("image", images.fileList[i].originFileObj);
      }
      await api
        .uploads()
        .post(formData)
        .then(async (response) => {
          response.data.forEach((res) => {
            api.informations().post({ id: 1, logoUrl: res.path });
          });
        });
    }
    // get images differences
    let intersection = oldImages.filter((x) => !images.fileList.includes(x));
    for (let i = 0; i < intersection.length; i++) {
      let uid = intersection[i].uid;
      await api.images(uid).delete();
    }
    //post settings data
    await api
      .informations()
      .post({
        id: 1,
        companyName: settings.companyName,
        description: settings.description,
        address1: settings.address1,
        address2: settings.address2,
        mail: settings.mail,
        geoLocation: settings.geoLocation,
        phone1: settings.phone1,
        phone2: settings.phone2,
        logoUrl: images.fileList
          ? images.fileList.length === 0
            ? ""
            : images.fileList.url
          : "",
        exchangeRate: settings.exchangeRate,
        showFilterInProductPage: settings.showFilterInProductPage,
        showManufacturerLogoInProductCard:
          settings.showManufacturerLogoInProductCard,
        mailSettings: settings.mailSettings,
        axonautToken: settings.axonautToken,
        taxRate: settings.taxRate,
        sendAutoToAxonaut: settings.sendAutoToAxonaut,
      })
      .then(async (response) => {
        await message.success("Settings updated", 1);
        window.location.reload();
      });
  };

  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
    }));
  };

  const handleImageChange = ({ fileList }) => {
    if (fileList.length > 0) {
      fileList[0].bannerName = "bottomBanner";
    }
    setImages((images) => ({ ...images, fileList }));
  };

  const handleProductPageFilterCheckBoxChange = (event) => {
    setSettings((settings) => ({
      ...settings,
      showFilterInProductPage: event.target.checked,
    }));
  };

  const handleSendAutoToAxonautCheckBoxChange = (event) => {
    setSettings((settings) => ({
      ...settings,
      sendAutoToAxonaut: event.target.checked,
    }));
  };

  const handleShowManufacturerLogo = (event) => {
    setSettings((settings) => ({
      ...settings,
      showManufacturerLogoInProductCard: event.target.checked,
    }));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div className="settings">
      <Form layout="vertical" onFinish={handleSubmit}>
        <Tabs className="dataview-nav" defaultActiveKey="1">
          <TabPane tab="Information settings" key="1">
            <Spin spinning={spinning}>
              <Layout>
                <Content style={{ padding: "32px 50px" }}>
                  <Row>
                    <Col span={12}>
                      <div>
                        <h2>Settings</h2>
                      </div>
                    </Col>
                    <Col span={12}></Col>
                  </Row>
                  <Row align="top" gutter={32}>
                    <Col span={6}>
                      <Form.Item>
                        <div className="clearfix">
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            accept=".jpg, .jpeg, .png, .gif"
                            listType="picture-card"
                            fileList={images.fileList}
                            onPreview={handlePreview}
                            onChange={handleImageChange}
                            name="bottomBanner"
                          >
                            {!!images.fileList && images.fileList.length >= 1
                              ? null
                              : uploadButton}
                          </Upload>
                          <Modal
                            visible={images.previewVisible}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{ width: "100%" }}
                              src={images.previewImage}
                            />
                          </Modal>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={18}>
                      <Tabs defaultActiveKey="1">
                        {languages.map((language) => {
                          return (
                            <TabPane tab={language.name} key={language.id}>
                              <Form.Item label="Company Name">
                                <Input
                                  type="text"
                                  name="companyName"
                                  onChange={(event) =>
                                    handleTranslateFieldsChange(
                                      event,
                                      language.code
                                    )
                                  }
                                  value={
                                    settings.companyName &&
                                    settings.companyName.some(
                                      (x) => x.languageCode === language.code
                                    )
                                      ? settings.companyName.find(
                                          (x) =>
                                            x.languageCode === language.code
                                        ).text
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Description">
                                <TextArea
                                  type="text"
                                  name="description"
                                  rows={4}
                                  onChange={(event) =>
                                    handleTranslateFieldsChange(
                                      event,
                                      language.code
                                    )
                                  }
                                  value={
                                    settings.description &&
                                    settings.description.some(
                                      (x) => x.languageCode === language.code
                                    )
                                      ? settings.description.find(
                                          (x) =>
                                            x.languageCode === language.code
                                        ).text
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Address 1">
                                <Input
                                  type="text"
                                  name="address1"
                                  onChange={(event) =>
                                    handleTranslateFieldsChange(
                                      event,
                                      language.code
                                    )
                                  }
                                  value={
                                    settings.address1 &&
                                    settings.address1.some(
                                      (x) => x.languageCode === language.code
                                    )
                                      ? settings.address1.find(
                                          (x) =>
                                            x.languageCode === language.code
                                        ).text
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Address 2">
                                <Input
                                  type="text"
                                  name="address2"
                                  onChange={(event) =>
                                    handleTranslateFieldsChange(
                                      event,
                                      language.code
                                    )
                                  }
                                  value={
                                    settings.address2 &&
                                    settings.address2.some(
                                      (x) => x.languageCode === language.code
                                    )
                                      ? settings.address2.find(
                                          (x) =>
                                            x.languageCode === language.code
                                        ).text
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Mail">
                                <Input
                                  type="text"
                                  name="mail"
                                  onChange={(event) =>
                                    handleTranslateFieldsChange(
                                      event,
                                      language.code
                                    )
                                  }
                                  value={
                                    settings.mail &&
                                    settings.mail.some(
                                      (x) => x.languageCode === language.code
                                    )
                                      ? settings.mail.find(
                                          (x) =>
                                            x.languageCode === language.code
                                        ).text
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Geo Location">
                                <Input
                                  type="text"
                                  name="geoLocation"
                                  onChange={(event) =>
                                    handleTranslateFieldsChange(
                                      event,
                                      language.code
                                    )
                                  }
                                  value={
                                    settings.geoLocation &&
                                    settings.geoLocation.some(
                                      (x) => x.languageCode === language.code
                                    )
                                      ? settings.geoLocation.find(
                                          (x) =>
                                            x.languageCode === language.code
                                        ).text
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Phone 1">
                                <Input
                                  type="text"
                                  name="phone1"
                                  onChange={(event) =>
                                    handleTranslateFieldsChange(
                                      event,
                                      language.code
                                    )
                                  }
                                  value={
                                    settings.phone1 &&
                                    settings.phone1.some(
                                      (x) => x.languageCode === language.code
                                    )
                                      ? settings.phone1.find(
                                          (x) =>
                                            x.languageCode === language.code
                                        ).text
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Phone 2">
                                <Input
                                  type="text"
                                  name="phone2"
                                  onChange={(event) =>
                                    handleTranslateFieldsChange(
                                      event,
                                      language.code
                                    )
                                  }
                                  value={
                                    settings.phone2 &&
                                    settings.phone2.some(
                                      (x) => x.languageCode === language.code
                                    )
                                      ? settings.phone2.find(
                                          (x) =>
                                            x.languageCode === language.code
                                        ).text
                                      : ""
                                  }
                                />
                              </Form.Item>
                            </TabPane>
                          );
                        })}
                      </Tabs>
                    </Col>
                  </Row>
                </Content>
              </Layout>
            </Spin>
          </TabPane>
          <TabPane tab="Mail settings" key="2">
            <Layout>
              <Content style={{ padding: "32px 50px" }}>
                <Row>
                  <div>
                    <h2>Mail settings</h2>
                  </div>
                </Row>
                <Row>
                  <MailSettings settings={settings} setSettings={setSettings} />
                </Row>
              </Content>
            </Layout>
          </TabPane>
          <TabPane tab="Additional" key="3">
            <Layout>
              <Content style={{ padding: "32px 50px" }}>
                <Row>
                  <Col span={12}>
                    <div>
                      <h2>Additional</h2>
                    </div>
                  </Col>
                  <Col span={12}></Col>
                </Row>
                <Col span={6}>
                  <Form.Item label="Exchange rate">
                    <InputNumber
                      min={0}
                      value={settings.exchangeRate}
                      onChange={onExchangeRateChange}
                    />
                  </Form.Item>
                  <Form.Item label="Tax rate">
                    <InputNumber
                      min={0}
                      value={settings.taxRate}
                      onChange={onTaxRateChange}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={settings.showFilterInProductPage}
                      onChange={handleProductPageFilterCheckBoxChange}
                    >
                      Show filter in product page
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={settings.showManufacturerLogoInProductCard}
                      onChange={handleShowManufacturerLogo}
                    >
                      Show manufacturer logo in product card
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={settings.sendAutoToAxonaut}
                      onChange={handleSendAutoToAxonautCheckBoxChange}
                    >
                      Send Invoice to Axonaut directly from customer's order
                    </Checkbox>
                  </Form.Item>
                  <Form.Item label="Axonaut token">
                    <Input
                      type="text"
                      name="axonautToken"
                      onChange={onAxonautTokenChange}
                      value={settings.axonautToken}
                    />
                  </Form.Item>
                </Col>
              </Content>
            </Layout>
          </TabPane>
          {/*           <TabPane tab="Geo Zones" key="3">
            <Layout>
              <Content style={{ padding: "32px 50px" }}>
                <Row>
                  <Col span={12}>
                    <div>
                      <h2>Geo Zones</h2>
                    </div>
                  </Col>
                  <Col span={12}></Col>
                </Row>
                <WrappedGeoZoneForm />
              </Content>
            </Layout>
          </TabPane> */}
        </Tabs>
        <Row type="flex" justify="center" align="middle">
          <Divider />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default Settings;
