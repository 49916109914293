import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Card,
  Statistic,
  Divider,
  Spin,
  DatePicker,
} from "antd";
import { ChartCard, MiniArea } from "ant-design-pro/lib/Charts";
import api from "./Api";
import moment from "moment";
import {
  CodeSandboxOutlined,
  CopyrightOutlined,
  FileDoneOutlined,
  FormOutlined,
  SolutionOutlined,
  ContainerOutlined,
  ReadOutlined,
  HomeOutlined,
  FolderAddOutlined,
  HddOutlined,
} from "@ant-design/icons";
import languages from "./Components/Languages/languages.json";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Meta } = Card;

const App = () => {
  const [ordersInfo, setOrdersInfo] = useState([]);
  const [ordersByDates, setOrdersByDates] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [spinning, setSpinning] = useState(true);
  const [products, setProducts] = useState(0);
  const [selectedDate, setSelectedDate] = useState([
    moment(new Date().setDate(new Date().getDate() - 7)).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ]);
  const [bestSellerProduct, setBestSellerProduct] = useState("");

  useEffect(() => {
    const getOrdersInfo = async () => {
      await api
        .documentsInfo()
        .get()
        .then((res) => {
          setOrdersInfo(res.data);
        });
    };
    const getBestSellerProduct = async () => {
      await api
        .bestSellerProduct()
        .get()
        .then((res) => {
          setBestSellerProduct(res.data);
        });
    };
    const getCustomers = async () => {
      await api
        .getCustomers()
        .get()
        .then((res) => {
          setCustomers(res.data);
        });
    };
    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((res) => {
          setProducts(res.data.length);
          setSpinning(false);
        });
    };
    getOrdersInfo();
    getBestSellerProduct();
    getCustomers();
    getProducts();
  }, []);

  useEffect(() => {
    const getOrdersInfoByDates = async () => {
      if (selectedDate[0] !== "") {
        await api
          .documentsInfo(selectedDate.join(","))
          .get()
          .then((res) => {
            setOrdersByDates(res.data);
          });
      }
    };
    getOrdersInfoByDates();
  }, [selectedDate]);

  const onDateRangeChange = async (date, dateString) => {
    setSelectedDate(dateString);
  };
  const dateFormat = "YYYY-MM-DD";

  return (
    <Content style={{ margin: "24px 16px 0" }}>
      <div style={{ padding: 24, textAlign: "center" }}>
        <Spin spinning={spinning}>
          <Row gutter={16}>
            <Col xs={24} lg={3}>
              <Card
                style={{
                  background: "#fc5c65",
                  boxShadow:
                    "0px 4px 9px 2px rgb(249 160 164), 0 1px 2px rgb(255 191 195)",
                }}
                bordered={false}
              >
                <Statistic
                  style={{ background: "#fc5c65" }}
                  title="Total orders"
                  valueStyle={{ fontSize: 36, fontWeight: 700, color: "white" }}
                  value={
                    ordersInfo && ordersInfo.length > 0 ? ordersInfo[0] : 0
                  }
                />
              </Card>
            </Col>
            <Col xs={24} lg={6}>
              <Card
                style={{
                  background: "#20bf6b",
                  boxShadow:
                    "0px 4px 9px 2px rgb(154 234 192), 0 1px 2px rgb(222 251 235)",
                }}
                bordered={false}
              >
                <Statistic
                  style={{ background: "#20bf6b" }}
                  title="Total amount"
                  valueStyle={{ fontSize: 36, fontWeight: 700, color: "white" }}
                  value={
                    ordersInfo && ordersInfo.length > 0 ? ordersInfo[1] : 0
                  }
                  suffix="€"
                />
              </Card>
            </Col>
            <Col xs={24} lg={6}>
              <Card
                style={{
                  background: "#778ca3",
                  boxShadow:
                    "0px 4px 9px 2px rgb(192 215 241), 0 1px 2px rgb(201 223 249)",
                }}
                bordered={false}
              >
                <Statistic
                  style={{ background: "#778ca3" }}
                  title="Verified customers/All"
                  valueStyle={{ fontSize: 36, fontWeight: 700, color: "white" }}
                  value={customers.filter((x) => x.state === "verified").length}
                  suffix={"/ " + customers.length}
                />
              </Card>
            </Col>
            <Col xs={24} lg={3}>
              <Card
                style={{
                  background: "#2bcbba",
                  boxShadow:
                    "0px 4px 9px 2px rgb(173 253 245), 0 1px 2px rgb(198 241 236)",
                }}
                bordered={false}
              >
                <Statistic
                  style={{ background: "#2bcbba" }}
                  title="Products"
                  valueStyle={{ fontSize: 36, fontWeight: 700, color: "white" }}
                  value={products}
                />
              </Card>
            </Col>
            <Col xs={24} lg={6}>
              <Card
                style={{
                  background: "#2980b9",
                  boxShadow:
                    "0px 4px 9px 2px rgb(180 218 243), 0 1px 2px rgb(192 219 236)",
                }}
                bordered={false}
              >
                <Statistic
                  style={{ background: "#2980b9" }}
                  title="Bestseller product"
                  valueStyle={{ fontSize: 18, fontWeight: 700, color: "white" }}
                  value={
                    bestSellerProduct &&
                    bestSellerProduct.some(
                      (x) => x.languageCode === languages[0].code
                    )
                      ? bestSellerProduct.find(
                          (x) => x.languageCode === languages[0].code
                        ).text
                      : ""
                  }
                />
              </Card>
            </Col>
            <Divider />
            <Col xs={24} lg={18}>
              <Row type="flex" justify="end" align="middle">
                <RangePicker
                  style={{ marginBottom: 4 }}
                  defaultValue={[
                    moment(selectedDate[0], dateFormat),
                    moment(selectedDate[1], dateFormat),
                  ]}
                  onChange={onDateRangeChange}
                />
              </Row>
              <ChartCard
                title={
                  ordersByDates && ordersByDates.length > 0
                    ? "Active dates: " +
                      ordersByDates[0].x +
                      "-" +
                      ordersByDates[ordersByDates.length - 1].x
                    : "No data"
                }
                total={
                  ordersByDates && ordersByDates.length > 0
                    ? Math.round(
                        ordersByDates.reduce((prev, cur) => prev + cur.y, 0) *
                          100
                      ) /
                        100 +
                      " €"
                    : 0 + " €"
                }
                contentHeight={134}
              >
                <MiniArea line height={45} data={ordersByDates} />
              </ChartCard>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col xs={24} lg={6}>
              <Link to="/products">
                <Card bordered={false}>
                  <Meta
                    avatar={<CodeSandboxOutlined style={{ fontSize: 32 }} />}
                    title="Products"
                    description="Click here to see products list"
                  />
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={6}>
              <Link to="/manufacturers">
                <Card bordered={false}>
                  <Meta
                    avatar={<CopyrightOutlined style={{ fontSize: 32 }} />}
                    title="Manufacturers"
                    description="Click here to see manufacturers list"
                  />
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={6}>
              <Link to="/customers">
                <Card bordered={false}>
                  <Meta
                    avatar={<SolutionOutlined style={{ fontSize: 32 }} />}
                    title="Customers"
                    description="Click here to see customers list"
                  />
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={6}>
              <Link to="/orders">
                <Card bordered={false}>
                  <Meta
                    avatar={<FileDoneOutlined style={{ fontSize: 32 }} />}
                    title="Orders"
                    description="Click here to see orders"
                  />
                </Card>
              </Link>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "16px" }}>
            <Col xs={24} lg={6}>
              <Link to="/categories">
                <Card bordered={false}>
                  <Meta
                    avatar={<ContainerOutlined style={{ fontSize: 32 }} />}
                    title="Categories"
                    description="Click here to see categories"
                  />
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={6}>
              <Link to="/storages">
                <Card bordered={false}>
                  <Meta
                    avatar={<HomeOutlined style={{ fontSize: 32 }} />}
                    title="Warehouses"
                    description="Click here to see warehouses"
                  />
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={6}>
              <Link to="/inputs">
                <Card bordered={false}>
                  <Meta
                    avatar={<FolderAddOutlined style={{ fontSize: 32 }} />}
                    title="Inputs"
                    description="Click here to see inputs"
                  />
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={6}>
              <Link to="/remainders">
                <Card bordered={false}>
                  <Meta
                    avatar={<HddOutlined style={{ fontSize: 32 }} />}
                    title="Remainders"
                    description="Click here to see remainders"
                  />
                </Card>
              </Link>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "16px" }}>
            <Col xs={24} lg={6}>
              <Link to="/posts">
                <Card bordered={false}>
                  <Meta
                    avatar={<ReadOutlined style={{ fontSize: 32 }} />}
                    title="Posts"
                    description="Click here to see posts list"
                  />
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={6}>
              <Link to="/pages">
                <Card bordered={false}>
                  <Meta
                    avatar={<FormOutlined style={{ fontSize: 32 }} />}
                    title="Pages"
                    description="Click here to see pages list"
                  />
                </Card>
              </Link>
            </Col>
          </Row>
        </Spin>
      </div>
    </Content>
  );
};

export default App;
