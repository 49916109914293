import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  LoadingOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Row,
  Input,
  Button,
  Layout,
  Col,
  message,
  Divider,
  Upload,
  Tabs,
  Modal,
  Checkbox,
  Spin,
  Form,
} from "antd";
import languages from "../Languages/languages.json";
import slugify from "slugify";
import api from "../../Api";
import PageEditor from "../PageEditor";
import { ValidateToken } from "../Utils/Validator";

const { Content } = Layout;
const API_URL = process.env.REACT_APP_API_URL;
const { TabPane } = Tabs;

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

//form updates and submit, get page stored data
const PageForm = ({ page, isNew }) => {
  const [inputs, setInputs] = useState(
    isNew
      ? {
          title: [],
          description: [],
          excerpt: [],
          imageUrl: "",
          slug: "",
        }
      : page
  );

  const [description, setDescription] = useState(inputs.description);
  const [modal, setModal] = useState({
    ModalText: "Confirm page delete",
    visible: false,
    confirmLoading: false,
  });
  const [images, setImages] = useState({
    fileList:
      page && page.imageUrl
        ? [
            {
              uid: page ? page : -1,
              name: "image",
              status: "done",
              url: `${page.imageUrl ? page.imageUrl : ""}`,
            },
          ]
        : [],
  });

  const handleSubmit = async () => {
    ValidateToken(history);
    if (
      !inputs.title.some((x) => x.languageCode === languages[0].code) ||
      (inputs.title.some((x) => x.languageCode === languages[0].code) &&
        inputs.title.find((x) => x.languageCode === languages[0].code).text ===
          "")
    ) {
      await message.error(`Page title in english is required`, 2);
      return;
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let pageID = params.get("pageID");

    let formData = new FormData();
    for (let i = 0; i < images.fileList.length; i++) {
      formData.append("image", images.fileList[i].originFileObj);
    }
    api
      .uploads()
      .post(formData)
      .then(async (response) => {
        response.data.forEach((res) => {
          api
            .pages()
            .post({ id: parseInt(pageID), imageUrl: `${API_URL}/${res.path}` });
        });
      });
    let slug = inputs.title.some((x) => x.languageCode === languages[0].code)
      ? inputs.title.find((x) => x.languageCode === languages[0].code).text
      : inputs.title[0].text;
    //post page data
    let data = {
      id: parseInt(pageID),
      title: inputs.title,
      description: description,
      excerpt: inputs.excerpt,
      imageUrl: images.fileList
        ? images.fileList.length === 0
          ? ""
          : images.fileList.url
        : "",
      imageIsFixed: inputs.imageIsFixed,
      isInTopNavigation: inputs.isInTopNavigation,
      isInNavigation: inputs.isInNavigation,
      slug: slugify(slug, {
        lower: true,
      }),
      ordering: inputs.ordering,
    };
    await api
      .pages()
      .post(data)
      .then(async (res) => {
        if (res.data && res.data.error) {
          await message.error(res.data.message, 2);
        } else {
          if (isNew) {
            await message.success("This page successfully created", 1);
            history.push(`/pages`);
            window.location.reload();
          } else {
            await message.success("This page successfully edited", 1);
            window.location.reload();
          }
        }
      });
  };
  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);

    await api.pages(inputs.id).delete();
    await message.warning("This page successfully deleted", 1);
    history.push(`/pages`);
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };
  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleTranslateFieldsChange = (event, languageCode) => {
    event.persist();
    let data = event.target.value;

    let tmpArray = inputs[event.target.name] ? inputs[event.target.name] : [];
    tmpArray = tmpArray.filter((x) => x.languageCode !== languageCode);
    tmpArray.push({ languageCode: languageCode, text: data });
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: tmpArray,
    }));
  };

  const handleImageChange = (img) => {
    setImages((images) => ({ ...images, fileList: img.fileList }));
  };

  const handleNavigationCheck = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      isInNavigation: event.target.checked,
    }));
  };

  const handleImageIsFixed = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      imageIsFixed: event.target.checked,
    }));
  };

  const handleTopNavigationCheck = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      isInTopNavigation: event.target.checked,
    }));
  };
  //Page form component

  const uploadButton = (
    <div>
      {images.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
    }));
  };

  return (
    <Tabs defaultActiveKey="1">
      {languages.map((language) => {
        return (
          <TabPane tab={language.name} key={language.id}>
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row type="flex" justify="space-between" align="top" gutter={16}>
                <Col span={12}>
                  <Form.Item label="Title">
                    <Input
                      type="text"
                      name="title"
                      onChange={(event) =>
                        handleTranslateFieldsChange(event, language.code)
                      }
                      value={
                        inputs.title &&
                        inputs.title.some(
                          (x) => x.languageCode === language.code
                        )
                          ? inputs.title.find(
                              (x) => x.languageCode === language.code
                            ).text
                          : ""
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Page excerpt">
                    <Input
                      type="text"
                      name="excerpt"
                      onChange={(event) =>
                        handleTranslateFieldsChange(event, language.code)
                      }
                      value={
                        inputs.excerpt &&
                        inputs.excerpt.some(
                          (x) => x.languageCode === language.code
                        )
                          ? inputs.excerpt.find(
                              (x) => x.languageCode === language.code
                            ).text
                          : ""
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Slug">
                    <Input
                      name="slug"
                      type="text"
                      onChange={handleInputChange}
                      value={inputs.slug}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Ordering">
                    <Input
                      name="ordering"
                      type="number"
                      onChange={handleInputChange}
                      value={inputs.ordering}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <div className="clearfix">
                      <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        accept=".jpg, .jpeg, .png, .gif"
                        listType="picture-card"
                        fileList={images.fileList}
                        beforeUpload={beforeUpload}
                        onPreview={handlePreview}
                        onChange={handleImageChange}
                      >
                        {!!images.fileList && images.fileList.length >= 1
                          ? null
                          : uploadButton}
                      </Upload>
                      <Modal
                        visible={images.previewVisible}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={images.previewImage}
                        />
                      </Modal>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={inputs.imageIsFixed}
                      onChange={handleImageIsFixed}
                    >
                      Image is fixed
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={inputs.isInNavigation}
                      onChange={handleNavigationCheck}
                    >
                      Navigation
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={inputs.isInTopNavigation}
                      onChange={handleTopNavigationCheck}
                    >
                      Top Navigation
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="center" align="middle">
                <Form.Item label="Description" style={{ width: "100%" }}>
                  <PageEditor
                    description={description}
                    setDescription={setDescription}
                    languageCode={language.code}
                  />
                </Form.Item>
                <Divider />
                <Row type="flex" gutter={32}>
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {isNew ? "Create" : "Update"}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    {isNew ? (
                      ""
                    ) : (
                      <Form.Item>
                        <Button type="danger" onClick={showModal}>
                          Delete
                        </Button>
                        <Modal
                          title="Are you sure?"
                          visible={modal.visible}
                          onOk={handleModalOk}
                          confirmLoading={modal.confirmLoading}
                          onCancel={handleModalCancel}
                        >
                          <p>{modal.ModalText}</p>
                        </Modal>
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Row>
            </Form>
          </TabPane>
        );
      })}
    </Tabs>
  );
};
//Page component
const Page = () => {
  const [page, setPage] = useState({});
  const [spinning, setSpinning] = useState(true);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isNew = params.get("isNew") === "true";

  useEffect(() => {
    const fetchPage = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let pageID = params.get("pageID");

      await api
        .pages(pageID)
        .get()
        .then((res) => {
          setPage(res.data[0]);
          setSpinning(false);
        })
        .catch((err) => console.log(err));
    };
    fetchPage();
  }, []);

  return (
    <div className="page">
      <Link className="back-icon" to="/pages">
        <ArrowLeftOutlined />
      </Link>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>{isNew ? "Add New" : ""}</h1>
            <Divider />
            {isNew ? (
              <PageForm page={page} isNew={isNew} />
            ) : (
              !!page.id && <PageForm page={page} isNew={isNew} />
            )}
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Page;
