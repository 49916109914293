import React, { useState, useEffect } from "react";
import {
  Row,
  Divider,
  message,
  List,
  Button,
  InputNumber,
  Select,
} from "antd";
import api from "../../Api";
import { ProductName, StorageName } from "../Utils/Common";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const { Option } = Select;

const InputDetails = ({ input }) => {
  const [products, setProducts] = useState([]);
  const [storages, setStorages] = useState([]);
  const [inputDetails, setInputDetails] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [deletedRows, setDeletedRows] = useState([]);
  const [changedRows, setChangedRows] = useState([]);
  const [totalSum, setTotalSum] = useState(input.totalSum);
  const [showEditButtons, setShowEditButtons] = useState(false);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportXLS = () => {
    let excelData = [];
    excelData.push(...inputDetails);

    excelData.forEach((row, index) => {
      row.quantity = Number(row.quantity);
      row.price = Number(row.price);
      row.totalPrice = Number((row.quantity * row.price).toFixed(2));
      row.inputDate =
        new Date(input.date).getFullYear() +
        "-" +
        (new Date(input.date).getMonth() + 1) +
        "-" +
        new Date(input.date).getDate();
      row.id = index + 1;
      row.productId = ProductName(products.find((x) => x.id === row.productId));
      row.notes = input.notes ? input.notes : "";
    });
    excelData.push({ totalPrice: Number(totalSum) });

    const ws = XLSX.utils.json_to_sheet(excelData, { origin: { r: 0, c: 0 } });
    var wscols = [
      { wch: 6 },
      { wch: 20 },
      { wch: 30 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 30 },
      { wch: 50 },
      { wch: 30 },
    ];

    ws["!cols"] = wscols;

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `Report for input ${
        excelData[0].inputUid +
        "-" +
        new Date(input.date).getFullYear() +
        "-" +
        (new Date(input.date).getMonth() + 1) +
        "-" +
        new Date(input.date).getDate()
      }` + fileExtension
    );
  };

  const setTotals = () => {
    let total = inputDetails.reduce((a, b) => a + b.price * b.quantity, 0);
    setTotalSum(Number(total.toFixed(2)));
  };

  const handleInputRowDelete = (rowId) => {
    let tempRows = [...deletedRows];
    tempRows.push(rowId);
    setDeletedRows(tempRows);
    setInputDetails(inputDetails.filter((x) => x.id !== rowId));
    let total = inputDetails
      .filter((x) => x.id !== rowId)
      .reduce((a, b) => a + b.price * b.quantity, 0);
    setTotalSum(Number(total.toFixed(2)));
  };

  const handlePriceChange = (price, rowId) => {
    let tempRows = [...changedRows];
    if (tempRows && tempRows.some((x) => x.rowId === rowId)) {
      let index = tempRows.findIndex((x) => x.rowId === rowId);
      tempRows[index].price = price;
    } else {
      tempRows.push({ id: rowId, price: price });
    }
    inputDetails.find((x) => x.id === rowId).price = price;
    setChangedRows(tempRows);
    setTotals();
  };

  const handleQuantityChange = (quantity, rowId) => {
    let tempRows = [...changedRows];
    if (tempRows && tempRows.some((x) => x.id === rowId)) {
      let index = tempRows.findIndex((x) => x.id === rowId);
      tempRows[index].quantity = quantity;
    } else {
      tempRows.push({ id: rowId, quantity: quantity });
    }
    inputDetails.find((x) => x.id === rowId).quantity = quantity;
    setChangedRows(tempRows);
    setTotals();
  };

  const editInput = () => {
    setShowEditButtons(true);
  };

  const updateInput = async () => {
    let err = false;
    inputDetails.forEach((row, index) => {
      if (
        inputDetails.some(
          (x) =>
            x.productId === row.productId &&
            row.inputUid === "" &&
            x.inputUid !== ""
        )
      ) {
        message.error(
          `There is a product with the same name ${index + 1} , ${
            inputDetails.findIndex(
              (x) =>
                x.productId === row.productId &&
                row.inputUid === "" &&
                x.inputUid !== ""
            ) + 1
          }`,
          1
        );
        err = true;
      }
    });
    if (inputDetails.length === 0) {
      message.error(`Products list is empty`, 1);
      err = true;
    }
    if (inputDetails.some((x) => x.storageId === 0)) {
      message.error(`Warehouse is required`, 1);
      err = true;
    }
    if (err) {
      return;
    }
    const inputsBody = [];
    //for new rows
    inputDetails
      .filter((x) => x.inputUid === "")
      .forEach((row) => {
        row.inputUid = input.id;
        inputsBody.push(row);
      });
    //for changed rows
    if (changedRows) {
      changedRows
        .filter((x) => x.id !== undefined)
        .forEach((row) => {
          inputsBody.push(row);
        });
    }
    await api
      .inputs()
      .post(inputsBody)
      .then((res) => console.log(res.data))
      .catch((ex) => console.log(ex.data));
    //for deleted rows
    if (deletedRows.length > 0) {
      await api
        .inputs()
        .delete({
          params: { ids: deletedRows },
        })
        .then((res) => console.log(res.data))
        .catch((ex) => console.log(ex.data));
    }
    if (changedRows || deletedRows) {
      await api
        .documents()
        .post({ id: input.id, totalSum: totalSum })
        .then((res) => console.log(res.data))
        .catch((ex) => console.log(ex.data));
      message.success("Input updated", 1);
      window.location.reload();
    }
    setShowEditButtons(false);
  };

  useEffect(() => {
    const getInputDetails = async () => {
      await api
        .inputs(input.id)
        .get()
        .then((response) => setInputDetails(response.data))
        .catch((err) => console.log(err));
    };
    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((response) => {
          setProducts(response.data);
          setLoadingProducts(false);
        })
        .catch((err) => console.log(err));
    };

    const getStorages = async () => {
      await api
        .storages()
        .get()
        .then((response) => {
          setStorages(response.data);
        })
        .catch((err) => console.log(err));
    };
    getInputDetails();
    getProducts();
    getStorages();
  }, [input.id]);

  const handleAddProduct = () => {
    let tmpRows = [...inputDetails];

    tmpRows.push({
      inputUid: "",
      productId: 0,
      quantity: "1",
      price: "0",
      storageId: storages.find((x) => x.isDefault).id,
    });
    setInputDetails(tmpRows);
  };

  const handleProductSelect = (productId, index) => {
    let tmpRows = [...inputDetails];

    tmpRows[index].productId = productId;
    setInputDetails(tmpRows);
    setTotals();
  };

  const handleStorageSelect = (storageId, rowId) => {
    let tempRows = [...changedRows];
    if (tempRows && tempRows.some((x) => x.rowId === rowId)) {
      let index = tempRows.findIndex((x) => x.rowId === rowId);
      tempRows[index].storageId = storageId;
    } else {
      tempRows.push({ id: rowId, storageId: storageId });
    }
    inputDetails.find((x) => x.id === rowId).storageId = storageId;
    setChangedRows(tempRows);
    setTotals();
  };

  return (
    <>
      <div>
        Input N:
        {input.id}
      </div>
      <div className="input-date">
        Input date:
        {" " +
          new Date(input.date).getFullYear() +
          "-" +
          (new Date(input.date).getMonth() + 1) +
          "-" +
          new Date(input.date).getDate()}
      </div>
      <div className="input-date">
        Notes:
        {" " + input.notes ? input.notes : ""}
      </div>
      <Button
        type="primary"
        className="green-btn"
        size="small"
        disabled={!showEditButtons}
        icon={<PlusOutlined />}
        onClick={handleAddProduct}
      ></Button>
      <List
        style={{ width: "100%" }}
        itemLayout="horizontal"
        loading={loadingProducts}
        size="small"
        footer={"Total " + totalSum + " €"}
        dataSource={inputDetails}
        renderItem={(row, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              title={
                row.inputUid === "" ? (
                  <>
                    <span>{(index + 1).toString() + ". "}</span>
                    <Select
                      showSearch
                      allowClear
                      style={{ width: "80%" }}
                      placeholder="Please select product"
                      onChange={(value) => handleProductSelect(value, index)}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {products
                        .filter(
                          ({ id: id1 }) =>
                            !inputDetails.some(
                              ({ productId: id2, inputUid: id3 }) =>
                                id2 === id1 && id3 !== ""
                            )
                        )
                        .map((product) => (
                          <Option value={product.id} key={product.id}>
                            {ProductName(product)}
                          </Option>
                        ))}
                    </Select>
                  </>
                ) : (
                  (index + 1).toString() +
                  ". " +
                  ProductName(products.find((x) => x.id === row.productId))
                )
              }
            />
            <div style={{ marginRight: 12 }}>
              {showEditButtons ? (
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select warehouse"
                  defaultValue={storages.find((x) => x.isDefault).id}
                  onChange={(value) => handleStorageSelect(value, row.id)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {storages.map((storage) => (
                    <Option value={storage.id} key={storage.id}>
                      {StorageName(storage)}
                    </Option>
                  ))}
                </Select>
              ) : (
                StorageName(storages.find((x) => x.id === row.storageId))
              )}
            </div>
            <div style={{ marginRight: 12 }}>
              Price:{" "}
              {showEditButtons ? (
                <InputNumber
                  size="small"
                  min={0}
                  onChange={(value) => handlePriceChange(value, row.id)}
                  value={row.price}
                />
              ) : (
                row.price
              )}
            </div>
            <div>
              Quantity:{" "}
              {showEditButtons ? (
                <InputNumber
                  size="small"
                  min={1}
                  onChange={(value) => handleQuantityChange(value, row.id)}
                  value={row.quantity}
                />
              ) : (
                row.quantity
              )}
            </div>
            <Button
              type="primary"
              size="small"
              style={{
                marginLeft: 4,
                display: showEditButtons ? "block" : "none",
              }}
              danger
              onClick={() => handleInputRowDelete(row.id)}
            >
              -
            </Button>
          </List.Item>
        )}
      />
      <Row type="flex" justify="center" align="middle">
        <Divider />
        <Button
          icon={<EditOutlined />}
          type="primary"
          onClick={showEditButtons ? updateInput : editInput}
          style={{ marginRight: 6 }}
        >
          {showEditButtons ? "Update input" : "Edit input"}
        </Button>
        <Divider />
        <Button
          type="primary"
          disabled={showEditButtons}
          onClick={exportXLS}
          className="green-btn"
        >
          Download xlsx
        </Button>
      </Row>
    </>
  );
};

export default InputDetails;
